import React, { useEffect, useState } from "react";
import ContractDetailsHeader from "./ContractDetailsHeader";
import ContractInfoCard from "./ContractInfoCard";
import ContractRolesCard from "./ContractRolesCard";
import { useNavigate, useParams } from "react-router-dom";
import { getContractDetails } from "./services";

const ContractDetails = () => {
  const [contractDetails, setContractDetails] = useState({});
  const [contractUpdated, setContractUpdated] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getContractDetails(id)
      .then((response) => {
        setContractDetails(response.data);
      })
      .catch((e) => console.log(e));
  }, [id, contractUpdated]);
  return (
    <div className="d-flex flex-column w-100">
      <ContractDetailsHeader
        contractDetails={contractDetails}
        setContractUpdated={setContractUpdated}
        navigate={navigate}
      />
      <div className="d-flex m-5 gap-5">
        <div className="col-3">
          <ContractInfoCard
            contractDetails={contractDetails}
            setContractUpdated={setContractUpdated}
          />
        </div>
        <div className="col-8">
          <ContractRolesCard
            contractDetails={contractDetails}
            setContractUpdated={setContractUpdated}
            navigate={navigate}
          />
        </div>
      </div>
    </div>
  );
};

export default ContractDetails;
