import React, { FC, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LeadsCard from './LeadsCard/LeadsCard';
import CustomersCard from './CustomersCard/CustomersCard';
import { getDashboard } from './services';

interface DashboardProps { }

const Dashboard: FC<DashboardProps> = () => {
/*
   useEffect(() => {
      getDashboard()
         .then((res: any) => {
            console.log(res);
         })
         .catch((e: any) => console.error(e));
   }, []);
*/
   return (
      <div data-testid="Dashboard" className="m-5">
         <h4>Bonjour Admin</h4>
         <div className="d-flex my-5">
            <Card className="col-6 m-2">
               <LeadsCard
               />
            </Card>
            <Card className="col-6 m-2">
               <CustomersCard
               />
            </Card>
         </div>
      </div>
   );
}

export default Dashboard;
