import { AxiosHeaders } from "axios";
import { config, keycloakAxios } from "../../config/config";


let headers = new AxiosHeaders();
headers.setContentType('application/json');


const register_user = (user_payload: any) => {
    return keycloakAxios.post(config.KEYCLOAK_BASE_URL + '/admin/realms/master/users', user_payload, {
        headers: headers,
        withCredentials: true
    })
}

const set_password = (user_endpoint: string, password_payload: any) => {
    return keycloakAxios.put(user_endpoint + '/reset-password', password_payload, {
        headers: headers,
        withCredentials: true
    })
}


export { register_user, set_password };