import React, { FC } from "react";
import "./index.scss";

const Button = ({ ...props }) => {
  return (
    <button
      className={`button ${props.styled}`}
      onClick={props.onClick}
      style={props?.style}
      disabled={props?.disabled}
    >
      {props.type === "leading-icon" && props.icon}

      {props.title && (<span>{props?.title}</span>)}

      {props.type === "trailing-icon" && props.icon}
    </button>
  );
};

export default Button;
