import React, { useEffect, useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import NavPage from "../pages";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "../pages/login/Login";
import Header from "../components/header/Header";
import { interceptResponse, interceptToken, refreshToken } from "../utils/token";
import ResetPassword from "../pages/reset-password/ResetPassword";

function AppContainer() {

  const [logged, setLogged] = useState(false);

  function reload() {

    setLogged(!!localStorage.getItem("access_token"));

    interceptToken();
    
    interceptResponse();

  }
  /*
    const cronRefreshToken = (interval: NodeJS.Timer) => {
      // FIXME
    }
  */
  useEffect(() => {
    reload();

    let interval: NodeJS.Timer;

    const expires_in = Number(localStorage.getItem('expires_in'));

    if (expires_in != null && expires_in > 0) {
      interval = setInterval(() => {

        refreshToken();
        
      }, expires_in * 900);
    }

    window.addEventListener("login", () => {
      console.log("login event add!");
      reload();

      const expires_in = Number(localStorage.getItem('expires_in'));

      if (expires_in != null && expires_in > 0) {
        clearInterval(interval);
        interval = setInterval(() => {

          refreshToken();

        }, expires_in * 900);
      }

    });

    window.addEventListener("logout", () => {
      console.log("logout event add!");

      localStorage.setItem('access_token', '');
      localStorage.setItem('refresh_token', '');

      localStorage.setItem('expires_in', '');
      localStorage.setItem('refresh_expires_in', '');

      // After clear localStorage items
      reload();

      clearInterval(interval);
    });

    return () => {

      clearInterval(interval);

      window.removeEventListener("login", () => {
        console.log("login event remove!");
        clearInterval(interval);
      });

      window.removeEventListener("logout", () => {
        console.log("logout event remove!");
        clearInterval(interval);
      });

    };

  }, []);

  if (logged)
    return (
      <>
        <BrowserRouter basename="/">
          <div className="d-flex-column">
            <Header />
            <NavPage />
          </div>
        </BrowserRouter>
      </>
    );
  else
    return (
      <>
        <BrowserRouter basename="/">
          <div className="d-flex-column">
            <Routes>
              <Route path="*" Component={Login} />
              <Route path="/password/reset" Component={ResetPassword} />
            </Routes>
          </div>
        </BrowserRouter>
      </>
    );
}

export default AppContainer;
