import React, { Fragment } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { Settings, SidebarData } from "../components/sidebar/sidebarData";
import Dashboard from "./Dashboard/Dashboard";
import Header from "../components/header/Header";
import Sidebar from "../components/sidebar/Sidebar";
import CreateQuoteAuto from "./quotes/automobile/quoteCreation";
import Register from "./register/Register";
import UserInfo from "./user-info/UserInfo";

const NavPage = () => {
  const location = useLocation();
  //const hideHeaderPaths = ["/login"]; {!hideHeaderPaths.includes(location.pathname) && <Header />}
  const hideSidebarPaths = ["/quote/new"];

  return (
    <>
      
      <div className="d-flex">
        {!hideSidebarPaths.includes(location.pathname.slice(0, 10)) && (
          <Sidebar />
        )}
        <Routes>
          {SidebarData.map((item) =>
            !item?.subItems ? (
              <Fragment key={item.path}>
                <Route
                  key={item.path}
                  path={item.path}
                  Component={item.component}
                />
                <Route
                  key={item.detailsPath}
                  path={item.detailsPath}
                  Component={item.detailsComponent}
                />
              </Fragment>
            ) : (
              item.subItems.map((subItem) => (
                <Fragment key={subItem.path}>
                  <Route
                    key={subItem.path}
                    path={subItem.path}
                    Component={subItem.component}
                  />
                  <Route
                    key={subItem.detailsPath}
                    path={subItem.detailsPath}
                    Component={subItem.detailsComponent}
                  />
                </Fragment>
              ))
            )
          )}

          {Settings.map((item, index) => (
            <Route key={index} path={item.path} Component={item.component} />
          ))}
          <Route path="/" Component={Dashboard} />
          <Route path="/register" Component={Register} />
          <Route path="/user-info" Component={UserInfo} />
        </Routes>
      </div>
      <Routes>
        <Route path="/quote/new/:id" Component={CreateQuoteAuto} />
      </Routes>
    </>
  );
};

export default NavPage;
