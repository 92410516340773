import { useEffect, useState } from "react";
import { getProductById } from "../products/services";
import { formatDate } from "../../utils/utilities";

const OfferInfoCard = ({ offerDetails, ...props }) => {
  const [productName, setProductName] = useState("");
  useEffect(() => {
    offerDetails?.productId &&
      getProductById(offerDetails.productId)
        .then((res) => setProductName(res.data.name))
        .catch((e) => console.log(e));
  }, [offerDetails]);
  return (
    <div className="info-card-container d-flex flex-column p-3 gap-3">
      <span className="title-element">Informations générales</span>

      <div className="d-flex flex-column gap-3">
        <div className="d-flex gap-3">
          <div className="d-flex flex-column col-6">
            <span className="title-element">Créé le : </span>
            <span>{formatDate(offerDetails.creationDate)}</span>
          </div>
          <div className="d-flex flex-column col-6">
            <span className="title-element">Mis à jour le : </span>
            <span>{formatDate(offerDetails.lastUpdate)}</span>
          </div>
        </div>
        <div className="d-flex gap-3">
          <div className="d-flex flex-column col-6">
            <span className="title-element">Produit : </span>
            <div className="d-flex gap-2">
              <span>{productName}</span>
            </div>
          </div>
          <div className="d-flex flex-column col-6">
            <span className="title-element">Statut : </span>

            <div className="d-flex gap-2">
              <span>{offerDetails.status}</span>
            </div>
          </div>
        </div>

        <div className="d-flex gap-3">
          <div className="d-flex flex-column col-6">
            <span className="title-element">Date de début : </span>

            <div className="d-flex gap-2">
              <span>{offerDetails.startDate}</span>
            </div>
          </div>
          <div className="d-flex flex-column col-6">
            <span className="title-element">Date de fin : </span>

            <div className="d-flex gap-2">
              <span>{offerDetails.endDate}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferInfoCard;
