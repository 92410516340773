import React, { useEffect, useState } from "react";
import Button from "../../components/ui/Button";
import { FiPlus } from "react-icons/fi";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import "./personnes.scss";
import { getAllCompanies } from "./services";
import AddCompanyModal from "./AddCompanyModal";
import { formatDate } from "../../utils/utilities";

const Companies = () => {
  const [pagination, setPagination] = useState({ page: 0, pageSize: 20 });
  const [addCompanyModalIsOpen, setAddCompanyModalIsOpen] = useState(false);
  const [companyAdded, setCompanyAdded] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  const navigate = useNavigate();

  const columns = [
    {
      field: "id",
      headerName: "Référence",
      valueGetter: (value) => `${value.slice(0, 8)}`,
      flex: 1,
    },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "code",
      headerName: "Code",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      sortable: false,
    },
    {
      field: "description",
      headerName: "Description",
      sortable: false,
      flex: 1,
    },
    {
      field: "creationDate",
      headerName: "Créé le",
      valueGetter: (value) => {
        return formatDate(value);
      },
      flex: 1,
    },
  ];

  useEffect(() => {
    getAllCompanies()
      .then((response) => {
        setCompanyList(response.data);
      })
      .catch((e) => console.log(e));
  }, [companyAdded]);

  return (
    <>
      <AddCompanyModal
        addCompanyModalIsOpen={addCompanyModalIsOpen}
        setAddCompanyModalIsOpen={setAddCompanyModalIsOpen}
        setCompanyAdded={setCompanyAdded}
      />
      <div className="d-flex flex-column gap-5 m-5 w-100">
        <div className="flex-row fs-4">Entreprises</div>
        {companyList.length ? (
          <>
            <div className="d-flex flex-row-reverse">
              <Button
                styled="primary"
                title="Créer une entreprise"
                type="trailing-icon"
                onClick={() => {
                  setAddCompanyModalIsOpen(true);
                }}
                icon={<FiPlus />}
              />
            </div>
            <div className="flex-row">
              <DataGrid
                rows={companyList}
                columns={columns}
                paginationModel={pagination}
                onPaginationModelChange={setPagination}
                disableColumnMenu
                disableColumnResize
                disableColumnSorting
                disableRowSelectionOnClick
                onRowClick={({ id }) => {
                  navigate(`/companies/${id}`);
                }}
              ></DataGrid>
            </div>
          </>
        ) : (
          <div className=" d-flex flex-column align-items-center gap-4 m-auto">
            <span>Il n’y a actuellement aucune entreprise dans la liste</span>
            <span>
              <Button
                styled="primary"
                title="Créer une entreprise"
                type="trailing-icon"
                onClick={() => {
                  setAddCompanyModalIsOpen(true);
                }}
                icon={<FiPlus />}
              />
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default Companies;
