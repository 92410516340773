import Button from "../../components/ui/Button";
import { FiX } from "react-icons/fi";
import Modal from "react-modal";
import { deleteOffer } from "./services";

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "650px",
    padding: "0px",
    height: "250px",
    overflow: "visible",
  },
};

const OfferArchive = ({
  offerId,
  offerArchiveIsOpen,
  setOfferArchiveIsOpen,
  setOfferArchived,
  ...props
}) => {
  const handleCloseModal = () => {
    setOfferArchiveIsOpen(false);
  };
  const handleSubmit = () => {
    offerId
      ? deleteOffer(offerId)
          .then((resp) => {
            handleCloseModal();
            setOfferArchived((prevState) => {
              return !prevState;
            });
          })
          .catch((e) => console.log(e))
      : console.log("Archive : empty offer Id");
  };
  return (
    <Modal isOpen={offerArchiveIsOpen} style={ModalStyle}>
      <div className="d-flex justify-content-end">
        <FiX
          size={24}
          style={{ cursor: "pointer", marginTop: "20px", marginRight: "20px" }}
          onClick={() => {
            handleCloseModal();
          }}
        />
      </div>
      <div className="d-flex flex-column p-4 gap-3">
        <span className="fw-bold fs-5">
          Vous êtes sur le point d'archiver une offre
        </span>
        <span className="fs-5">
          Si vous cliquez sur "Archiver", ceci entraînera l'archivage définitif
          de l'offre.
        </span>

        <div className="d-flex justify-content-end gap-3">
          <Button
            title={"Annuler"}
            styled={"secondary"}
            onClick={() => handleCloseModal()}
          />
          <Button
            title={"Archiver"}
            styled={"primary"}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default OfferArchive;
