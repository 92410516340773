import React, { FC, useEffect, useState } from "react";
import Stepper from "../../../../components/ui/Stepper";
import { RiLogoutBoxLine } from "react-icons/ri";
import Button from "../../../../components/ui/Button";
import "./style.scss";
import { useNavigate, useParams } from "react-router-dom";
import PersonalInfo from "./PersonalInfo";
import ProductInfo from "./ProductInfo";
import Offers from "./Offers";
import Payment from "./Payment";
import Contract from "./Contract";
import { getQuoteById } from "../services";
interface QuoteProps {}

const CreateQuoteAuto: FC<QuoteProps> = () => {
  const steps = [
    { index: "01", label: "Informations tiers" },
    { index: "02", label: "Informations véhicules" },
    { index: "03", label: "Offre" },
    { index: "04", label: "Paiement" },
    { index: "05", label: "Signature de contrat" },
  ];
  const navigate = useNavigate();
  const { id } = useParams();
  const [step, setStep] = useState(0);
  const [quoteUpdated, setQuoteUpdated] = useState(false);
  const [quoteDetails, setQuoteDetails] = useState({});

  useEffect(() => {
    getQuoteById(id)
      .then((res: any) => {
        setQuoteDetails(res.data);
      })
      .catch((e: any) => console.log(e));
  }, [quoteUpdated, id]);

  return (
    <div className="d-flex">
      <div className="step-container" style={{ width: "330px" }}>
        <span>
          <Button
            styled="primary"
            title="Quitter"
            type="leading-icon"
            onClick={() => {
              navigate("/quote_automobile");
            }}
            icon={<RiLogoutBoxLine />}
          />
        </span>
        <Stepper steps={steps} actualStep={step} labelSide="right" />
      </div>
      <div className="m-5 w-100">
        {step === 0 && (
          <PersonalInfo
            setStep={setStep}
            quoteId={id}
            quoteDetails={quoteDetails}
            setQuoteUpdated={setQuoteUpdated}
          />
        )}
        {step === 1 && (
          <ProductInfo
            setStep={setStep}
            quoteId={id}
            quoteDetails={quoteDetails}
            setQuoteUpdated={setQuoteUpdated}
          />
        )}
        {step === 2 && (
          <Offers
            setStep={setStep}
            quoteDetails={quoteDetails}
            setQuoteUpdated={setQuoteUpdated}
          />
        )}
        {step === 3 && <Payment setStep={setStep} quoteId={id} />}
        {step === 4 && <Contract setStep={setStep} quoteId={id} />}
      </div>
    </div>
  );
};

export default CreateQuoteAuto;
