import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import Button from "../../../../components/ui/Button";
import Input from "../../../../components/ui/Input";
import { updateQuote } from "../services";

const ProductInfo = ({
  setStep,
  quoteId,
  quoteDetails,
  setQuoteUpdated,
  ...props
}) => {
  const [vehiculeData, setVehiculeData] = useState({});

  useEffect(() => {
    if (!!quoteDetails?.productData) {
      setVehiculeData(quoteDetails.productData);
    } else {
      setVehiculeData({});
    }
  }, [quoteDetails]);

  const handleSubmit = () => {
    updateQuote(quoteId, { productData: vehiculeData })
      .then((resp) => {
        setStep(2);
        setQuoteUpdated((prev) => {
          return !prev;
        });
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="d-flex flex-column gap-5">
      <span className="d-flex gap-3 align-items-center">
        <FaArrowLeft
          size={36}
          cursor={"pointer"}
          onClick={() => {
            setStep(0);
          }}
        />
        <h2>Étape 2: informations véhicule</h2>
      </span>

      <div className="col-8 d-flex flex-column gap-2">
        <div className="d-flex flex-column gap-4">
          <div className="d-flex justify-content-between">
            <div className="col-5">
              <Input
                style={{ width: "100%" }}
                label={"Marque"}
                placeholder={"Marque"}
                styled={"filled-input"}
                value={vehiculeData.marque}
                setValue={(value) => {
                  setVehiculeData({ ...vehiculeData, marque: value });
                }}
              />
            </div>
            <div className="col-5">
              <Input
                style={{ width: "100%" }}
                label={"Modèle"}
                placeholder={"Modèle"}
                styled={"filled-input"}
                value={vehiculeData.model}
                setValue={(value) => {
                  setVehiculeData({ ...vehiculeData, model: value });
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="col-5">
              <Input
                style={{ width: "100%" }}
                label={"Energie"}
                placeholder={"Energie"}
                styled={"filled-input"}
                value={vehiculeData.energy}
                setValue={(value) => {
                  setVehiculeData({ ...vehiculeData, energy: value });
                }}
              />
            </div>
            <div className="col-5">
              <Input
                style={{ width: "100%" }}
                label={"Puissance"}
                placeholder={"Puissance"}
                styled={"filled-input"}
                value={vehiculeData.puissance}
                setValue={(value) => {
                  setVehiculeData({ ...vehiculeData, puissance: value });
                }}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div className="col-5">
              <Input
                style={{ width: "100%" }}
                label={"Immatriculation"}
                placeholder={"Immatriculation"}
                styled={"filled-input"}
                value={vehiculeData.car_plate}
                setValue={(value) => {
                  setVehiculeData({ ...vehiculeData, car_plate: value });
                }}
              />
            </div>
            <div className="col-5">
              <Input
                style={{ width: "100%" }}
                label={"Date de mise en circulation"}
                placeholder={"Date de mise en circulation"}
                styled={"filled-input"}
                value={vehiculeData.circulationDate}
                setValue={(value) => {
                  setVehiculeData({ ...vehiculeData, circulationDate: value });
                }}
              />
            </div>
          </div>
          <hr />
        </div>

        <span className="d-flex justify-content-end">
          <Button
            styled="primary"
            title="Enregistrer et continuer"
            onClick={() => {
              handleSubmit();
            }}
          />
        </span>
      </div>
    </div>
  );
};

export default ProductInfo;
