import React, { FC, useEffect, useState } from "react";
import { getLeadsCount } from "./services";

interface LeadsCardProps {
}

const LeadsCard: FC<LeadsCardProps> = (props) => {

   const [number, setNumber] = useState(0);

   useEffect(() => {
      getLeadsCount()
         .then((res: any) => {
            console.log(res);
            setNumber(res.data);
         })
         .catch((e: any) => console.error(e));
   }, []);

   return (
      <div data-testid="LeadsCard">
         <div className="d-flex flex-column m-3">
            <div className="d-flex mb-3">
               <h6>Nombre de prospects</h6>
            </div>
            <div className="d-flex mb-3">
               <h1>{number}</h1>
            </div>
         </div>
      </div>
   );
};


export default LeadsCard;
