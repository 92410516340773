import { config } from "../../config/config";

const getAllProducts = () => {
  return config.axios.get(config.PRODUCT_QUERIES_BASE_URL + "/product");
};

const getAllEnabledProducts = () => {
  return config.axios.get(config.PRODUCT_QUERIES_BASE_URL + "/product/enabled");
};

const getProductById = (id) => {
  return config.axios.get(config.PRODUCT_QUERIES_BASE_URL + "/product/" + id);
};

const createProduct = (data) => {
  return config.axios.post(config.PRODUCT_COMMANDS_BASE_URL + "/product", data);
};

const getProductDetails = (id) => {
  return config.axios.get(config.PRODUCT_QUERIES_BASE_URL + "/product/" + id);
};

const updateProduct = (data) => {
  return config.axios.put(config.PRODUCT_COMMANDS_BASE_URL + "/product", data);
};

const deleteProduct = (id) => {
  return config.axios.delete(
    config.PRODUCT_COMMANDS_BASE_URL + "/product/" + id
  );
};

const getAllProductProperties = () => {
  return config.axios.get(config.PRODUCT_RESSOURCES_BASE_URL + "/properties");
};

const createProductProperty = (data) => {
  return config.axios.post(
    config.PRODUCT_COMMANDS_BASE_URL + "/properties/create-new-property",
    data
  );
};
// TODO
const deleteProductProperty = (id) => {
  return config.axios.delete(
    config.PRODUCT_COMMANDS_BASE_URL + "/properties/" + id
  );
};

const addPropertyToProduct = (id, data) => {
  return config.axios.post(
    config.PRODUCT_COMMANDS_BASE_URL + "/properties/create-new-property",
    +"/product/" + id + "/add-property",
    data
  );
};

const updateProductProperty = (id, data) => {
  return config.axios.post(
    config.PRODUCT_COMMANDS_BASE_URL + "/properties/update-property/" + id,
    data
  );
};

const getAllOffers = () => {
  return config.axios.get(config.PRODUCT_RESSOURCES_BASE_URL + "/offers");
};

const addOfferToProduct = (id, data) => {
  return config.axios.post(
    config.PRODUCT_COMMANDS_BASE_URL + "/product/" + id + "/add-offer",
    data
  );
};

export {
  getAllProducts,
  getAllEnabledProducts,
  getProductById,
  createProduct,
  getProductDetails,
  updateProduct,
  deleteProduct,
  getAllProductProperties,
  createProductProperty,
  deleteProductProperty,
  addPropertyToProduct,
  updateProductProperty,
  getAllOffers,
  addOfferToProduct,
};
