import React, { FC, useState } from 'react';
import Card from '@mui/material/Card';
import Input from "../../components/ui/Input";
import Button from '../../components/ui/Button';
import { decodedToken, refreshToken } from '../../utils/token';
import { update_user_info, change_password } from './service';


interface UserInfoProps { }

const UserInfo: FC<UserInfoProps> = () => {

  const tokenInfo: any = decodedToken();

  const isAdmin: boolean = tokenInfo?.resource_access?.account?.roles?.includes('manage-account');

  const [given_name, setGivenName] = useState(tokenInfo.given_name)
  const [family_name, setFamilyName] = useState(tokenInfo.family_name)

  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState('')


  const onChangeUserInfo = () => {

    /*
        {
      "username": "john.doe",
      "email": "john.doe@example.com",
      "firstName": "John",
      "lastName": "Doe",
      "attributes":{},
      "groups":[],
    }
          */

    const user_info_payload = {
      "firstName": given_name,
      "lastName": family_name,
      "enabled": true
    }

    const user_id = tokenInfo.sub;
    update_user_info(user_id, user_info_payload)
      .then(function (response: any) {
        console.log('Keycloak update_user_info response', JSON.stringify(response))

        refreshToken();

      })
      .catch(function (error: any) {
        console.log(error);
      });

  }

  const onChangePassword = () => {

    // Set initial error values to empty
    setPasswordError('')
    // Check if the user has entered fields correctly

    if ('' === password) {
      setPasswordError('Please enter a password')
      return
    }

    if (password.length < 8) {
      setPasswordError('The password must be 8 characters or longer')
      return
    }

    const password_payload = {
      "type": "password",
      "value": password, // FIXME crypt
      "temporary": false
    }

    const user_id = tokenInfo.sub;
    console.log('Keycloak this user_id', user_id)

    change_password(user_id, password_payload)
      .then(function (response: any) {
        console.log('Keycloak profile change password response', JSON.stringify(response))
      })
      .catch(function (error: any) {
        console.log(error);
      });

  }


  return (
    <div data-testid="UserInfo" className="container">
      <br />
      <Card className="col-12 m-2">
        <div className="{'titleContainer'} mx-5 mt-3">
          <h6>Informations profil</h6>
        </div>
        <br />
        <div className="{'inputContainer'} mx-5">
          <Input
            style={{ width: "100%" }}
            value={given_name}
            label={"Prénom"}
            placeholder="Enter your given name here"
            setValue={(value: React.SetStateAction<string>) => {
              setGivenName(value)
            }}
            styled={"filled-input"}
          //errorMsg={}
          />
        </div>
        <br />
        <div className="{'inputContainer'} mx-5">
          <Input
            style={{ width: "100%" }}
            value={family_name}
            label={"Nom"}
            placeholder="Enter your family name here"
            setValue={(value: React.SetStateAction<string>) => {
              setFamilyName(value)
            }}
            styled={"filled-input"}
          //errorMsg={}
          />
        </div>
        <br />
        {isAdmin && <div className="{'buttonContainer'} mx-5 mb-3">
          <Button styled={'primary'} onClick={onChangeUserInfo} title={'Modifier'}/>
        </div>}
      </Card>
      <br />
      {isAdmin && <Card className="col-12 m-2">
        <div className="{'titleContainer'} mx-5 mt-3">
          <h6>Modifier mot de passe</h6>
        </div>
        <br />
        <div className="{'inputContainer'} mx-5">
          <Input
            type={"password"}
            style={{ width: "100%" }}
            value={password}
            label={"Nouveau mot de passe"}
            placeholder="Enter your password here"
            setValue={(value: React.SetStateAction<string>) => {
              setPassword(value)
            }}
            //onChange={(ev: { target: { value: React.SetStateAction<string>; }; }) => setPassword(ev.target.value)}
            styled={"filled-input"}
            errorMsg={passwordError}
          />
        </div>
        <br />
        <div className="{'buttonContainer'} mx-5 mb-3">
          <Button styled={'primary'} onClick={onChangePassword} title={'Enregistrer'}/>
        </div>
      </Card>}
    </div>
  )
}

export default UserInfo;
