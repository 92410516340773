import React, { useEffect, useState } from "react";
import { FiCheck, FiEdit3, FiX } from "react-icons/fi";
import Input from "../../components/ui/Input";
import { updateCompany } from "./services";

const CompanyDetailsHeader = ({
  companyDetails,
  setCompanyUpdated,
  navigate,
  ...props
}) => {
  const [fields, setFields] = useState({});
  const [editFields, setEditFields] = useState({
    name: false,
    email: false,
  });

  const handleEditFields = (key, val) => {
    setEditFields({ ...editFields, [key]: val });
  };

  const handleFields = (key, val) => {
    setFields({ ...fields, [key]: val });
  };

  const getFields = () => {
    setFields({
      name: companyDetails?.name,
      email: companyDetails?.email,
    });
  };

  const handleSubmit = (key) => {
    const data = { ...companyDetails, ...fields };
    updateCompany(data)
      .then(() => {
        setCompanyUpdated((prevState) => {
          return !prevState;
        });
        setEditFields({ ...editFields, [key]: false });
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getFields();
  }, [companyDetails]);

  return (
    <div className="details-header d-flex flex-column px-5 py-4 gap-5">
      <div className="d-flex gap-2">
        <span
          className="pathlink-item"
          onClick={() => {
            navigate("/companies");
          }}
        >
          {"Entreprises >"}
        </span>
        <span>{companyDetails.name}</span>
      </div>
      <div className="d-flex flex-column gap-2">
        <div>
          <span className="title-element">Référence : </span>
          <span>{companyDetails.id?.slice(0, 8)}</span>
        </div>
        {editFields["name"] ? (
          <div className="d-flex gap-2 align-items-center">
            <span className="title-element">Nom : </span>
            <Input
              styled={"filled-input"}
              value={fields.name}
              setValue={(val) => {
                handleFields("name", val);
              }}
            />
            <span
              className="icon-btn"
              onClick={() => {
                handleSubmit("name");
              }}
            >
              <FiCheck />
            </span>
            <span
              className="icon-btn"
              onClick={() => {
                handleEditFields("name", false);
              }}
            >
              <FiX />
            </span>
          </div>
        ) : (
          <div className="d-flex gap-2 align-items-center">
            <span className="fs-5">{companyDetails?.name}</span>
            <span
              className="icon-btn"
              onClick={() => {
                handleEditFields("name", true);
              }}
            >
              <FiEdit3 />
            </span>
          </div>
        )}
        <div className="d-flex gap-2 align-items-center">
          <span className="title-element">Email : </span>
          {editFields["email"] ? (
            <>
              <Input
                styled={"filled-input"}
                value={fields.email}
                setValue={(val) => {
                  handleFields("email", val);
                }}
              />
              <span
                className="icon-btn"
                onClick={() => {
                  handleSubmit("email");
                }}
              >
                <FiCheck />
              </span>
              <span
                className="icon-btn"
                onClick={() => {
                  handleEditFields("email", false);
                }}
              >
                <FiX />
              </span>
            </>
          ) : (
            <>
              <span>{companyDetails.email}</span>
              <span
                className="icon-btn"
                onClick={() => {
                  handleEditFields("email", true);
                }}
              >
                <FiEdit3 />
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailsHeader;
