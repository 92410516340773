import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCompanyDetails } from "./services";
import CompanyDetailsHeader from "./CompanyDetailsHeader";
import CompanyInfoCard from "./CompanyInfoCard";
import LegalPersonRelationsCard from "../relations/LegalPersonRelationsCard";
import NaturalPersonRelationsCard from "../relations/NaturalPersonRelationsCard";

const CompanyDetails = () => {
  const [companyDetails, setCompanyDetails] = useState({});
  const [companyUpdated, setCompanyUpdated] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getCompanyDetails(id)
      .then((response) => {
        setCompanyDetails(response.data);
      })
      .catch((e) => console.log(e));
  }, [id, companyUpdated]);

  return (
    <div className="d-flex flex-column w-100">
      <CompanyDetailsHeader
        companyDetails={companyDetails}
        setCompanyUpdated={setCompanyUpdated}
        navigate={navigate}
      />
      <div className="d-flex m-5 gap-5">
        <div className="col-3">
          <CompanyInfoCard
            companyDetails={companyDetails}
            setCompanyUpdated={setCompanyUpdated}
          />
        </div>
        <div className="col-8 d-flex flex-column gap-5">
          <LegalPersonRelationsCard
            personDetails={companyDetails}
            personType={"LP"}
            setPersonUpdated={setCompanyUpdated}
            navigate={navigate}
          />
          <NaturalPersonRelationsCard
            personDetails={companyDetails}
            personType={"LP"}
            setPersonUpdated={setCompanyUpdated}
            navigate={navigate}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
