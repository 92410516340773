import { AxiosHeaders } from "axios";
import { config, keycloakAxios } from "../../config/config";


let headers = new AxiosHeaders();
headers.setContentType('application/json');


const update_user_info = (user_id: string, user_info_payload: any) => {
    const endpoint_uri = config.KEYCLOAK_BASE_URL + '/admin/realms/master/users/' + user_id;

    console.log('Keycloak update_user_info endpoint_uri', endpoint_uri)

    return keycloakAxios.put(endpoint_uri, user_info_payload, {
        headers: headers,
        withCredentials: true
    })
}

const change_password = (user_id: string, password_payload: any) => {
    const endpoint_uri = config.KEYCLOAK_BASE_URL + '/admin/realms/master/users/' + user_id + '/reset-password';

    console.log('Keycloak change_password endpoint_uri', endpoint_uri)

    return keycloakAxios.put(endpoint_uri, password_payload, {
        headers: headers,
        withCredentials: true
    })
}


export { update_user_info, change_password };