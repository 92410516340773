//import { useState } from "react";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { formatDate } from "../../utils/utilities";

const tabs = ["Personnes", "Entreprises", "Contrats", "Produits"];

const Types = [
  { id: -1, value: null, label: "Aucun type selectionné" },
  { id: 1, value: "String", label: "Chaîne de caractère" },
  { id: 2, value: "Number", label: "Numéro" },
  { id: 3, value: "Date", label: "Date" },
  { id: 4, value: "List", label: "Liste" },
  { id: 5, value: "Boolean", label: "Boolean" },
];

const renderConditions = (row) => {
  return (
    <div className="d-flex flex-column">
      {Object.entries(row.validationRules).map(([key, val], index) => {
        return <span key={index}>{key + " " + val}</span>;
      })}
    </div>
  );
};

//const [propertyId, setPropertyId] = useState({});
//const [propertyArchiveIsOpen, setPropertyArchiveIsOpen] = useState(false);

const ActionCell = (params) => {
  return (
    <div>
      <MdModeEdit
        className="mx-2"
        style={{ cursor: "pointer" }}
        size={24}
        onClick={(event) => {
          event.stopPropagation();
          //setPropertyModalIsOpen(true);
          //setPropertyData(params.row);
        }}
      />

      <MdDelete
        className="mx-2"
        style={{ cursor: "pointer" }}
        size={24}
        onClick={(event) => {
          event.stopPropagation();
          //setPropertyArchiveIsOpen(true);
          //setPropertyId(params.row?.id);
          console.log(params);
        }}
        //visibility={(params.row?.status === "ARCHIVED") ? "hidden" : "visible"}
      />
    </div>
  );
};

const columns = [
  {
    field: "id",
    headerName: "Référence",
    valueGetter: (value) => `${value.slice(0, 8)}`,
    flex: 1,
  },
  {
    field: "label",
    headerName: "Label",
    flex: 1,
  },
  {
    field: "code",
    headerName: "Code",
    flex: 1,
  },
  {
    field: "propertyType",
    headerName: "Type",
    flex: 1,
  },
  {
    field: "validationRules",
    headerName: "Conditions",
    renderCell: ({ row }) => renderConditions(row),
    flex: 1,
  },
  {
    field: "creationDate",
    headerName: "Créé le",
    valueGetter: (value) => {
      return formatDate(value);
    },
    flex: 1,
  },
  {
    field: "actions",
    headerName: "Actions",
    renderCell: ActionCell,
    flex: 1,
  },
];

export { tabs, Types, columns };
