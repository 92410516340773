import { useEffect, useState } from "react";
import { FiCheck, FiEdit3, FiX } from "react-icons/fi";
import Input from "../../components/ui/Input";
import { patchContract, updateContract } from "./services";

const ContractDetailsHeader = ({
  contractDetails,
  setContractUpdated,
  navigate,
  ...props
}) => {
  const [fields, setFields] = useState({});
  const [editFields, setEditFields] = useState({ name: false });

  const handleEditFields = (key, val) => {
    setEditFields({ ...editFields, [key]: val });
  };

  const handleFields = (key, val) => {
    setFields({ ...fields, [key]: val });
  };

  const getFields = () => {
    setFields({
      name: contractDetails?.name,
    });
  };

  const handleSubmit = (key) => {
    /*
    const data = [{
      "op": "replace",
      "path": "/name",
      "value": fields.name
    }]
    patchContract(contractDetails.id, data)
    */
    const data = { id: contractDetails.id, ...fields };
    updateContract(data)
      .then(() => {
        setContractUpdated((prevState) => {
          return !prevState;
        });
        setEditFields({ ...editFields, [key]: false });
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getFields();
  }, [contractDetails]);

  return (
    <div className="details-header d-flex flex-column px-5 py-4 gap-5">
      <div className="d-flex gap-2">
        <span
          className="pathlink-item"
          onClick={() => {
            navigate("/contracts");
          }}
        >
          {"Contrats >"}
        </span>
        <span>{contractDetails.name}</span>
      </div>
      <div className="d-flex flex-column gap-2">
        <div>
          <span className="title-element">Référence : </span>
          <span>{contractDetails.id?.slice(0, 8)}</span>
        </div>

        {editFields["name"] ? (
          <div className="d-flex gap-2 align-items-center">
            <span className="title-element">Nom : </span>
            <Input
              styled={"filled-input"}
              value={fields.name}
              setValue={(val) => {
                handleFields("name", val);
              }}
            />
            <span
              className="icon-btn"
              onClick={() => {
                handleSubmit("name");
              }}
            >
              <FiCheck />
            </span>
            <span
              className="icon-btn"
              onClick={() => {
                handleEditFields("name", false);
              }}
            >
              <FiX />
            </span>
          </div>
        ) : (
          <div className="d-flex gap-2 align-items-center">
            <span className="fs-4">{contractDetails.name}</span>
            <span
              className="icon-btn"
              onClick={() => {
                handleEditFields("name", true);
              }}
            >
              <FiEdit3 />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContractDetailsHeader;
