import { useState } from "react";
import Modal from "react-modal";
import Input from "../../components/ui/Input";
import Dropdown from "../../components/ui/Dropdown";
import CustomSwitch from "../../components/ui/CustomSwitch";
import { FiX } from "react-icons/fi";
import { Types } from "./config";
import Button from "../../components/ui/Button";
const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    padding: "0px",
  },
};

const CreatePropretyModal = ({
  modalIsOpen,
  setModalIsOpen,
  setPropertyAdded,
  onCreate,
  ...props
}) => {
  const [addPropertyData, setAddPropertyData] = useState({});
  const [validationRules, setValidationRules] = useState({});
  const [listElements, setListElements] = useState([""]);

  const handleChangeListElements = (val, index) => {
    const ListItems = listElements.map((itm, i) => {
      if (i === index) {
        return val;
      } else {
        return itm;
      }
    });
    setListElements(ListItems);
  };

  const handleCloseAddModal = () => {
    setModalIsOpen(false);
    setAddPropertyData({});
    setValidationRules({});
    setListElements([""]);
  };

  const handleSubmit = () => {
    const data = {
      ...addPropertyData,
      validationRules:
        addPropertyData.type === "List"
          ? { ...validationRules, closedValues: listElements }
          : { ...validationRules },
    };
    onCreate(data)
      .then((response) => {
        handleCloseAddModal();
        setPropertyAdded((prevState) => {
          return !prevState;
        });
      })
      .catch((e) => console.log(e));
  };

  return (
    <Modal isOpen={modalIsOpen} style={ModalStyle}>
      <div className="modal-header">
        <h5>Créer une propriété</h5>
        <FiX
          size={24}
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleCloseAddModal();
          }}
        />
      </div>
      <div className="d-flex flex-column p-4 gap-4">
        <Input
          label={"Label*"}
          styled={"filled-input"}
          value={addPropertyData?.label || ""}
          setValue={(value) => {
            setAddPropertyData({ ...addPropertyData, label: value });
          }}
        />
        <Input
          label={"Code"}
          styled={"filled-input"}
          value={addPropertyData?.code || ""}
          setValue={(value) => {
            setAddPropertyData({ ...addPropertyData, code: value });
          }}
        />{" "}
        <Dropdown
          label={"Type"}
          value={addPropertyData.type}
          options={Types}
          setValue={(value) => {
            setAddPropertyData({ ...addPropertyData, type: value });

            if (value === "Date") {
              setValidationRules({ isFutureAllowed: true });
            } else setValidationRules({});
          }}
        />
        {addPropertyData.type === "String" && (
          <div>
            <span>Nombre de caractère</span>
            <div className="d-flex gap-2">
              <Input
                styled={"filled-input"}
                value={validationRules.minLength || ""}
                placeholder={"Min"}
                setValue={(value) => {
                  setValidationRules({
                    ...validationRules,
                    minLength: Number(value),
                  });
                }}
              />
              <Input
                styled={"filled-input"}
                value={validationRules.maxLength || ""}
                placeholder={"Max"}
                setValue={(value) => {
                  setValidationRules({
                    ...validationRules,
                    maxLength: Number(value),
                  });
                }}
              />
            </div>
          </div>
        )}
        {addPropertyData.type === "Number" && <></>}
        {addPropertyData.type === "Date" && (
          <div className="d-flex justify-content-between">
            <span>Date future autorisée</span>
            <CustomSwitch
              state={validationRules.isFutureAllowed}
              onToggleState={() => {
                setValidationRules({
                  ...validationRules,
                  isFutureAllowed: !validationRules.isFutureAllowed,
                });
              }}
            />
          </div>
        )}
        {addPropertyData.type === "List" && (
          <div className="d-flex flex-column gap-4">
            {listElements.map((el, index) => (
              <div className="d-flex justify-content-between align-items-end gap-2">
                <Input
                  label={`item ${index + 1}`}
                  placeholder={"Saisir le texte à ajouter dans la liste"}
                  styled={"standard-input"}
                  value={el}
                  setValue={(val) => {
                    handleChangeListElements(val, index);
                  }}
                />
                <span>
                  {index !== 0 && (
                    <FiX
                      size={24}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const ListItems = listElements.filter(
                          (el, i) => i !== index
                        );
                        setListElements(ListItems);
                      }}
                    />
                  )}
                </span>
              </div>
            ))}
            <span
              className="fw-medium w-25"
              role="button"
              onClick={() => {
                setListElements([...listElements, ""]);
              }}
            >
              Ajouter +
            </span>
          </div>
        )}
        <div className="d-flex justify-content-end gap-3">
          <Button
            title={"Annuler"}
            styled={"secondary"}
            onClick={handleCloseAddModal}
          />
          <Button
            title={"Créer une propriété"}
            styled={"primary"}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreatePropretyModal;
