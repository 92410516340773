import React, { useEffect, useState } from "react";
import Input from "../../components/ui/Input";
import { FiCheck, FiEdit3, FiX } from "react-icons/fi";
import Dropdown from "../../components/ui/Dropdown";
import {
  addPropertyToPerson,
  getAllPersonProperties,
  updatePropertyToPerson,
} from "./services";
import CustomSwitch from "../../components/ui/CustomSwitch";
import { formatDate } from "../../utils/utilities";

const PersonInfoCard = ({ personDetails, setPersonUpdated, ...props }) => {
  const [restProperties, setRestProperties] = useState([]);
  const [personProperties, setPersonProperties] = useState([]);
  const [allProperties, setAllProperties] = useState([]);
  const [error, setError] = useState({});

  const handleAddProperties = (key, prop, val) => {
    const properties = restProperties.map((el) => {
      if (el.key === key) {
        return { ...el, [prop]: val };
      } else {
        return el;
      }
    });
    setRestProperties(properties);
  };

  const handleEditProperties = (key, prop, val) => {
    const properties = personProperties.map((el) => {
      if (el.key === key) {
        return { ...el, [prop]: val };
      } else {
        return el;
      }
    });
    setPersonProperties(properties);
  };
  const getPersonProperties = () => {
    let personprops = [];
    if (!!personDetails.additionalData) {
      personprops = Object.entries(personDetails.additionalData).map(
        ([key, value]) => {
          return {
            key: key,
            label: allProperties.find((el) => el.code === key).label,
            isEdit: false,
            value: value,
            type: typeof value,
            closedValues: Array.isArray(value)
              ? allProperties.find((el) => el.code === key).validationRules
                  .closedValues
              : null,
          };
        }
      );
    }
    return setPersonProperties(personprops.sort((a, b) => a.key < b.key));
  };
  const getMissingProperties = () => {
    getAllPersonProperties()
      .then((res) => {
        let missingprops = res.data.map((el) => {
          return {
            key: el.code,
            label: el.label,
            isAdd: false,
            value: el.propertyType === "Boolean" ? false : "",
            type: el.propertyType,
            closedValues: el?.validationRules?.closedValues || null,
          };
        });
        setAllProperties(res.data);
        if (!!personDetails.additionalData) {
          missingprops = missingprops.filter(
            (el) => !Object.keys(personDetails.additionalData).includes(el.key)
          );
        }
        return setRestProperties(missingprops.sort((a, b) => a.key < b.key));
      })
      .catch((e) => console.log(e));
  };

  const handleSubmitAddProperties = (key) => {
    const data = {
      data: {
        [key]: restProperties.find((el) => el.key === key).value,
      },
    };
    addPropertyToPerson(personDetails.id, data)
      .then(() => {
        setPersonUpdated((prevState) => {
          return !prevState;
        });
      })
      .catch((e) => {
        setError({ ...error, [key]: e.response.data });
      });
  };
  const handleSubmitEditProperties = (key) => {
    const data = {
      data: {
        [key]: personProperties.find((el) => el.key === key).value,
      },
    };
    updatePropertyToPerson(personDetails.id, data)
      .then(() => {
        setPersonUpdated((prevState) => {
          return !prevState;
        });
      })
      .catch((e) => {
        setError({ ...error, [key]: e.response.data });
      });
  };
  useEffect(() => {
    getMissingProperties();
    getPersonProperties();
  }, [personDetails]);
  console.log(personProperties);
  return (
    <div className="info-card-container d-flex flex-column p-3 gap-3">
      <span className="title-element">A propos de cette personne</span>

      <div className="d-flex flex-column gap-2">
        <div className="d-flex flex-column">
          <span className="title-element">Créé le : </span>
          <span>{formatDate(personDetails.creationDate)}</span>
        </div>

        {personProperties.map(
          ({ key, label, isEdit, value, type, closedValues }) => (
            <div key={key} className="d-flex flex-column">
              <span className="title-element">{label}</span>
              {!!isEdit ? (
                <div className="d-flex gap-2 align-items-center">
                  {type === "boolean" ? (
                    <CustomSwitch
                      state={value}
                      onToggleState={() => {
                        handleEditProperties(key, "value", !value);
                      }}
                    />
                  ) : Array.isArray(value) ? (
                    <Dropdown
                      value={value[0]}
                      options={closedValues.map((el, i) => {
                        return { id: i, value: el, label: el };
                      })}
                      setValue={(val) => {
                        handleEditProperties(key, "value", [val]);
                      }}
                      errorMsg={error[key]}
                    />
                  ) : (
                    <Input
                      value={value}
                      type={type === "number" && "number"}
                      styled={"filled-input"}
                      setValue={(val) => {
                        handleEditProperties(key, "value", val);
                      }}
                      errorMsg={error[key]}
                    />
                  )}
                  <span
                    className="icon-btn"
                    onClick={() => {
                      handleSubmitEditProperties(key);
                    }}
                  >
                    <FiCheck />
                  </span>
                  <span
                    className="icon-btn"
                    onClick={() => {
                      handleEditProperties(key, "isEdit", false);
                    }}
                  >
                    <FiX />
                  </span>
                </div>
              ) : (
                <div className="d-flex gap-2">
                  <span>
                    {type === "boolean" ? (value ? "Oui" : "Non") : value}
                  </span>
                  <span
                    className="icon-btn"
                    onClick={() => {
                      handleEditProperties(key, "isEdit", true);
                    }}
                  >
                    <FiEdit3 />
                  </span>
                </div>
              )}
            </div>
          )
        )}

        {restProperties.map(
          ({ key, label, isAdd, value, type, closedValues }) => (
            <div key={key} className="d-flex flex-column">
              <span className="title-element">{label}</span>
              {!!isAdd ? (
                <div className="d-flex gap-2 align-items-center">
                  {type === "Boolean" ? (
                    <CustomSwitch
                      state={value}
                      onToggleState={() => {
                        handleAddProperties(key, "value", !value);
                      }}
                    />
                  ) : type === "List" ? (
                    <Dropdown
                      value={value}
                      options={[
                        { id: -1, value: null, label: "Choisir" },
                        ...closedValues.map((el, i) => {
                          return { id: i, value: el, label: el };
                        }),
                      ]}
                      setValue={(val) => {
                        handleAddProperties(key, "value", [val]);
                      }}
                      errorMsg={error[key]}
                    />
                  ) : (
                    <Input
                      styled={"filled-input"}
                      value={value}
                      type={type === "Number" && "number"}
                      setValue={(val) => {
                        handleAddProperties(key, "value", val);
                      }}
                      errorMsg={error[key]}
                    />
                  )}
                  <span
                    className="icon-btn"
                    onClick={() => {
                      handleSubmitAddProperties(key);
                    }}
                  >
                    <FiCheck />
                  </span>
                  <span
                    className="icon-btn"
                    onClick={() => {
                      handleAddProperties(key, "isAdd", false);
                    }}
                  >
                    <FiX />
                  </span>
                </div>
              ) : (
                <span
                  className="relation-link-name"
                  onClick={() => {
                    handleAddProperties(key, "isAdd", true);
                  }}
                >
                  Définir
                </span>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default PersonInfoCard;
