import React, { useEffect, useState } from "react";
import Button from "../../components/ui/Button";
import { FiPlus } from "react-icons/fi";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import "./personnes.scss";
import AddPersonModal from "./AddPersonModal";
import { getAllPersons } from "./services";
import { formatDate } from "../../utils/utilities";

const Personnes = () => {
  const [pagination, setPagination] = useState({ page: 0, pageSize: 20 });
  const [addPersonModalIsOpen, setAddPersonModalIsOpen] = useState(false);
  const [userAdded, setUserAdded] = useState(false);
  const [personList, setPersonList] = useState([]);

  const navigate = useNavigate();

  const columns = [
    {
      field: "id",
      headerName: "Référence",
      valueGetter: (value) => `${value.slice(0, 8)}`,
      flex: 1,
    },
    {
      field: "gender",
      headerName: "Genre",
      valueGetter: (value) => `${value === "MALE" ? "Mr" : "Mme"}`,
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Nom",
      flex: 1,
    },
    {
      field: "firstName",
      headerName: "Prénom",
      flex: 1,
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",

      sortable: false,
      flex: 1,
    },
    {
      field: "creationDate",
      headerName: "Créé le",
      valueGetter: (value) => {
        return formatDate(value);
      },
      flex: 1,
    },
  ];

  useEffect(() => {
    getAllPersons()
      .then((response) => {
        setPersonList(response.data);
      })
      .catch((e) => console.log(e));
  }, [userAdded]);

  return (
    <>
      <AddPersonModal
        addPersonModalIsOpen={addPersonModalIsOpen}
        setAddPersonModalIsOpen={setAddPersonModalIsOpen}
        setUserAdded={setUserAdded}
      />
      <div className="d-flex flex-column gap-5 m-5 w-100">
        <div className="flex-row fs-4">Personnes</div>
        {personList.length ? (
          <>
            <div className="d-flex flex-row-reverse">
              <Button
                styled="primary"
                title="Créer une personne"
                type="trailing-icon"
                onClick={() => {
                  setAddPersonModalIsOpen(true);
                }}
                icon={<FiPlus />}
              />
            </div>
            <div className="flex-row">
              <DataGrid
                rows={personList}
                columns={columns}
                paginationModel={pagination}
                onPaginationModelChange={setPagination}
                disableColumnMenu
                disableColumnResize
                disableColumnSorting
                disableRowSelectionOnClick
                onRowClick={({ id }) => {
                  navigate(`/persons/${id}`);
                }}
              />
            </div>
          </>
        ) : (
          <div className=" d-flex flex-column align-items-center gap-4 m-auto">
            <span>Il n’y a actuellement aucune personne dans la liste</span>
            <span>
              <Button
                styled="primary"
                title="Créer une personne"
                type="trailing-icon"
                onClick={() => {
                  setAddPersonModalIsOpen(true);
                }}
                icon={<FiPlus />}
              />
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default Personnes;
