import React, { FC } from "react";
import "./index.scss";

interface StepperProps {
  steps: any[];
  actualStep: number;
  labelSide: side;
}

type side = "right" | "left";

const Stepper: FC<StepperProps> = ({ steps, actualStep, labelSide }) => {
  return (
    <div className="step d-flex flex-column">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`d-flex flex-row ${
            labelSide === "left" && "justify-content-end"
          }`}
        >
          {labelSide === "left" && (
            <div style={{ paddingTop: "10px" }}>{step.label}</div>
          )}
          <div className="d-flex flex-column align-items-center">
            <div className={`circle ${actualStep >= index && "done"}`}>
              {step.index}
            </div>
            {index !== steps.length - 1 && (
              <span className={`line ${actualStep < index && "next"}`}></span>
            )}
          </div>
          {labelSide === "right" && (
            <div style={{ paddingTop: "10px" }}>{step.label}</div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Stepper;
