import React, { useState, useEffect } from "react";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import "./index.scss";
import CustomCheckbox from "./CustomCheckBox";
const DropdownMultiple = ({
  dataList,
  checkedItems,
  onSelectItems,
  style,
  placeholder,
  searchEnabled,
  selectedValue,
  ...props
}) => {
  const [showItems, setShowItems] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedItems, setSelectedItems] = useState(checkedItems || []);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    setFilteredData(
      dataList.filter((el) =>
        el.label
          .toLocaleLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(search.toLocaleLowerCase())
      )
    );
  }, [dataList, search]);

  useEffect(() => {
    if (!!onSelectItems) {
      onSelectItems(selectedItems);
    }
  }, [selectedItems]);

  return (
    <>
      <div
        className="d-inline-flex flex-column"
        style={{ width: style?.width, position: "relative" }}
      >
        <div className="searcharea">
          {searchEnabled ? (
            <input
              className="dropdown-search-input"
              type="text"
              placeholder={placeholder}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              disabled={!showItems}
            ></input>
          ) : (
            <span style={{ color: "grey" }}>{placeholder}</span>
          )}
          {showItems ? (
            <FiChevronUp onClick={() => setShowItems(false)} />
          ) : (
            <FiChevronDown onClick={() => setShowItems(true)} />
          )}
        </div>
        {showItems && (
          <div className="items">
            {filteredData.map((el) => (
              <div key={el.code} className="item d-flex gap-3">
                <CustomCheckbox
                  dataList={selectedItems}
                  setDataList={setSelectedItems}
                  checked={selectedItems.find((item) => item.id === el.id)}
                  value={el}
                />
                <span>{el.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default DropdownMultiple;
