import { Fragment, useState } from "react";
import { SidebarData, Settings } from "./sidebarData";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import "./sidebar.scss";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const [sidebaritems, setSidebaritems] = useState(SidebarData);
  const [showSidebar, setShowSidebar] = useState(true);
  const handleMenu = (index) => {
    const menuItems = sidebaritems.map((el, i) => {
      if (index === i) {
        return { ...el, isOpen: !el.isOpen };
      } else {
        return el;
      }
    });
    setSidebaritems(menuItems);
  };

  return (
    <>
      {showSidebar ? (
        <div className="sidebar-container">
          <div className="sidebar-top">
            <span className="sidebar-title">Produits</span>
            <div className="sidebar-items">
              {sidebaritems.map((item, index) =>
                !item?.subItems ? (
                  <NavLink
                    to={item.path}
                    key={index}
                    style={({ isActive }) =>
                      isActive
                        ? {
                            backgroundColor: "#00DB9533",
                            textDecoration: "none",
                            color: "#000000",
                            width: "100%",
                          }
                        : { textDecoration: "none", color: "#000000" }
                    }
                  >
                    <span className="sidebar-item py-1">{item.title}</span>
                  </NavLink>
                ) : (
                  <Fragment key={index}>
                    <div
                      key={index}
                      className={`sidebar-item ${item.isOpen && "active"}`}
                      onClick={() => {
                        handleMenu(index);
                      }}
                    >
                      <span className={`${item.isOpen && "active"}`}>
                        {item.title}
                      </span>
                      {item.isOpen ? (
                        <IoIosArrowUp color="#009C6A" />
                      ) : (
                        <IoIosArrowDown />
                      )}
                    </div>
                    {item.isOpen &&
                      item?.subItems.map((el, index) => (
                        <NavLink
                          to={el.path}
                          key={index}
                          style={({ isActive }) =>
                            isActive
                              ? {
                                  backgroundColor: "#00DB9533",
                                  textDecoration: "none",
                                  color: "#000000",
                                  width: "100%",
                                }
                              : { textDecoration: "none", color: "#000000" }
                          }
                        >
                          <span className="sidebar-subitem">{el.title}</span>
                        </NavLink>
                      ))}
                  </Fragment>
                )
              )}
            </div>
          </div>
          <div className="sidebar-settings">
            <div className="sidebar-title">Paramètres</div>
            <div className="sidebar-items">
              {Settings.map((el, index) => (
                <NavLink
                  to={el.path}
                  key={index}
                  style={({ isActive }) =>
                    isActive
                      ? {
                          fontWeight: 500,
                          textDecoration: "none",
                          color: "#000000",
                        }
                      : { textDecoration: "none", color: "#000000" }
                  }
                >
                  <span className="sidebar-item">{el.title}</span>
                </NavLink>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: "93vh" }}></div>
      )}
    </>
  );
};

export default Sidebar;
