import React, { useEffect, useState } from "react";
import Button from "../../../components/ui/Button";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { createQuote, getAllQuotes } from "./services";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { formatDate } from "../../../utils/utilities";
import { DataGrid } from "@mui/x-data-grid";

const QuoteAutoList = () => {
  const [quotesList, setQuotesList] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 20 });

  const navigate = useNavigate();

  const ActionCell = (params) => {
    return (
      <div>
        <MdModeEdit
          className="mx-2"
          style={{ cursor: "pointer" }}
          size={24}
          onClick={(event) => {
            event.stopPropagation();
          }}
          visibility={"visible"}
        />
        <MdDelete
          className="mx-2"
          style={{ cursor: "pointer" }}
          size={24}
          onClick={(event) => {
            event.stopPropagation();
          }}
          visibility={"visible"}
        />
      </div>
    );
  };
  const columns = [
    {
      field: "id",
      headerName: "Numéro de devis",
      valueGetter: (value) => `${value.slice(0, 8)}`,
      flex: 1,
    },

    {
      field: "creationDate",
      headerName: "Créé le",
      valueGetter: (value) => {
        return formatDate(value);
      },
      flex: 1,
    },
    {
      field: "lastUpdate",
      headerName: "Mis à jour le",
      valueGetter: (value) => {
        return formatDate(value);
      },
      flex: 1,
    },
    {
      field: "status",
      headerName: "Statut",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: ActionCell,
      flex: 1,
    },
  ];

  const createBlankQuote = () => {
    createQuote()
      .then((res) => {
        navigate(`/quote/new/${res.data}`);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getAllQuotes()
      .then((res) => setQuotesList(res.data))
      .catch((e) => console.log(e));
  }, []);

  return (
    <>
      <div className="d-flex flex-column gap-5 m-5 w-100">
        <div className="flex-row fs-4">Devis automobiles</div>
        {quotesList.length ? (
          <>
            <div className="d-flex flex-row-reverse">
              <Button
                styled="primary"
                title="Créer un devis"
                type="trailing-icon"
                onClick={() => {
                  createBlankQuote();
                }}
                icon={<FiPlus />}
              />
            </div>
            <div className="flex-row">
              <DataGrid
                rows={quotesList}
                columns={columns}
                paginationModel={pagination}
                onPaginationModelChange={setPagination}
                disableColumnMenu
                disableColumnResize
                disableColumnSorting
                disableRowSelectionOnClick
                onRowClick={({ id }) => {
                  navigate(`/quote/new/${id}`);
                }}
              ></DataGrid>
            </div>
          </>
        ) : (
          <div className=" d-flex flex-column align-items-center gap-4 m-auto">
            <span>Il n’y a actuellement aucun devis dans la liste</span>
            <span>
              <Button
                styled="primary"
                title="Créer un devis"
                type="trailing-icon"
                onClick={() => {
                  createBlankQuote();
                }}
                icon={<FiPlus />}
              />
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default QuoteAutoList;
