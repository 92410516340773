import { config } from "../../config/config";

const getAllOffers = () => {
  return config.axios.get(config.OFFER_QUERIES_BASE_URL + "/offer");
};

const getOffersByProduct = (productId) => {
  return config.axios.get(
    config.OFFER_QUERIES_BASE_URL + "/offer/byProduct/" + productId
  );
};

const getOfferDetails = (offerId) => {
  return config.axios.get(config.OFFER_QUERIES_BASE_URL + "/offer/" + offerId);
};

const createOffer = (data) => {
  return config.axios.post(config.OFFER_COMMANDS_BASE_URL + "/offer", data);
};

const updateOffer = (offerId, data) => {
  return config.axios.put(
    config.OFFER_COMMANDS_BASE_URL + "/offer/" + offerId,
    data
  );
};

const deleteOffer = (id) => {
  return config.axios.delete(config.OFFER_COMMANDS_BASE_URL + "/offer/" + id);
};

const createPricing = (data) => {
  return config.axios.post(config.PRICING_COMMANDS_BASE_URL + "/pricing", data);
};

const updatePricing = (priceId, data) => {
  return config.axios.put(
    config.PRICING_COMMANDS_BASE_URL + "/pricing/" + priceId,
    data
  );
};

const getAllPricingByOffer = (offerId) => {
  return config.axios.get(
    config.PRICING_QUERIES_BASE_URL + "/pricing/byOffer/" + offerId
  );
};

export {
  getAllOffers,
  getOffersByProduct,
  getOfferDetails,
  createOffer,
  updateOffer,
  deleteOffer,
  createPricing,
  updatePricing,
  getAllPricingByOffer,
};
