import { config } from "../../config/config";

const getAllRelations = () => {
  return config.axios.get(config.PERSON_RESSOURCES_BASE_URL + "/relations");
};
const createRelation = (data) => {
  return config.axios.post(
    config.PERSON_RESSOURCES_BASE_URL + "/relations",
    data
  );
};

const getAllPersonByIdLegalPersonLinks = (id) => {
  return config.axios.get(
    config.PERSON_QUERIES_BASE_URL + "/person/" + id + "/links/legal-person"
  );
};
const getAllPersonByIdNaturalPersonLinks = (id) => {
  return config.axios.get(
    config.PERSON_QUERIES_BASE_URL + "/person/" + id + "/links/natural-person"
  );
};

export {
  getAllRelations,
  createRelation,
  getAllPersonByIdLegalPersonLinks,
  getAllPersonByIdNaturalPersonLinks,
};
