import Keycloak from 'keycloak-js';
import { config } from "../config/config";

//const BASE_URL = process.env.REACT_APP_BASE_URL;


const keycloak: Keycloak = new Keycloak({
    url: config.KEYCLOAK_BASE_URL,
    realm: 'master',
    clientId: 'iosis'
});

async function GetKeycloakInstance() {

    if (!keycloak.token)
        await keycloak.init({
            onLoad: 'login-required',
            enableLogging: true,
            checkLoginIframe: true,
            flow: 'hybrid',
            //redirectUri: BASE_URL
    /*
    onLoad: 'check-sso',
    silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`
    */})

    return keycloak;
}

export default GetKeycloakInstance;