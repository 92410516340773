import React, { useEffect, useState } from "react";
import Button from "../../components/ui/Button";
import { FiPlus } from "react-icons/fi";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import AddRelationModal from "./AddRelationModal";
import { getAllRelations } from "./services";
import { formatDate } from "../../utils/utilities";

const Relations = () => {
  const [relationsList, setRelationsList] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 20 });
  const [relationModal, setRelationModal] = useState(false);
  const [relationAdded, setRelationAdded] = useState(false);

  const columns = [
    {
      field: "id",
      headerName: "Référence",
      valueGetter: (value) => `${value.slice(0, 8)}`,
      flex: 1,
    },
    {
      field: "label",
      headerName: "Label",
      flex: 1,
    },
    {
      field: "code",
      headerName: "Code",
      flex: 1,
    },
    {
      field: "creationDate",
      headerName: "Créé le",
      valueGetter: (value) => {
        return formatDate(value);
      },
      flex: 1,
    },
  ];

  useEffect(() => {
    getAllRelations()
      .then((response) => {
        setRelationsList(response.data);
      })
      .catch((e) => console.log(e));
  }, [relationAdded]);
  return (
    <div className="d-flex flex-column gap-5 m-5 w-100">
      <AddRelationModal
        relationModal={relationModal}
        setRelationModal={setRelationModal}
        relationAdded={relationAdded}
        setRelationAdded={setRelationAdded}
      />
      <div className="flex-row fs-4">Relations</div>
      {relationsList.length ? (
        <>
          <div className="d-flex flex-row-reverse">
            <Button
              styled="primary"
              title="Créer une relation"
              type="trailing-icon"
              onClick={() => {
                setRelationModal(true);
              }}
              icon={<FiPlus />}
            />
          </div>

          <div className="flex-row">
            <DataGrid
              rows={relationsList}
              columns={columns}
              paginationModel={pagination}
              onPaginationModelChange={setPagination}
              getRowHeight={() => "auto"}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 1,
                },
              }}
              disableColumnMenu
              disableColumnResize
              disableColumnSorting
              disableRowSelectionOnClick
              onRowClick={({ id }) => {}}
            ></DataGrid>
          </div>
        </>
      ) : (
        <div className=" d-flex flex-column align-items-center gap-4 m-auto">
          <span>Il n’y a actuellement aucune relation dans la liste</span>
          <span>
            <Button
              styled="primary"
              title="Créer une relation"
              type="trailing-icon"
              onClick={() => {
                setRelationModal(true);
              }}
              icon={<FiPlus />}
            />
          </span>
        </div>
      )}
    </div>
  );
};

export default Relations;
