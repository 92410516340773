import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa6";
import Button from "../../../../components/ui/Button";
import {
  getAllPricingByOffer,
  getOffersByProduct,
} from "../../../offers/services";
import DropdownSB from "../../../../components/ui/DropdownSB";
import { updateQuote } from "../services";
import { config } from "../../../../config/config";

const Offers = ({ setStep, quoteDetails, setQuoteUpdated, ...props }) => {
  const PricingType = {
    ONESHOT: "Comptant",
    MONTH: "Mensuel",
    YEAR: "Annuel",
  };
  const PriceLabels = {
    ONESHOT: "Comptant",
    MONTH: "/Mois",
    YEAR: "/An",
  };
  const [offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState({});
  const [pricings, setPricings] = useState([]);
  const [selectedPrice, setSelectedPrice] = useState({});

  useEffect(() => {
    getOffersByProduct(config.vehicule_product_id)
      .then((res) => {
        setOffers(res.data);
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    if (offers.length > 0) {
      //Search for Elligible offer
      const Offer = offers.find((offer) => {
        let elligible = true;
        for (let i = 0; i < offer.rules.length; i++) {
          const rule = offer.rules[i];
          const RuleValue = JSON.parse(rule.value);
          if (rule.propertyType === "Number") {
            if (
              quoteDetails.productData[rule.code] < RuleValue.minValue ||
              quoteDetails.productData[rule.code] > RuleValue.maxValue
            ) {
              elligible = false;
              break;
            }
          }
          if (rule.propertyType === "Date") {
            const minDate = new Date(RuleValue.minValue);
            const maxDate = new Date(RuleValue.maxValue);
            const targetDate = new Date(quoteDetails.productData[rule.code]);
            if (targetDate < minDate || targetDate > maxDate) {
              elligible = false;
              break;
            }
          }
          if (rule.propertyType === "Boolean") {
            if (RuleValue.value != quoteDetails.productData[rule.code]) {
              elligible = false;
              break;
            }
          }
          if (rule.propertyType === "List") {
            if (
              !RuleValue.selectedValues.includes(
                quoteDetails.productData[rule.code]
              )
            ) {
              elligible = false;
              break;
            }
          }
        }
        return elligible;
      });
      setSelectedOffer(Offer);
    }
  }, [offers, quoteDetails.productData.puissance]);

  useEffect(() => {
    if (!!selectedOffer?.id) {
      getAllPricingByOffer(selectedOffer.id)
        .then((res) => {
          const Prices = res.data.map((el) => {
            return {
              value: el.id,
              label: PricingType[el.periodType],
              ...el,
            };
          });
          setPricings(Prices);
        })
        .catch((e) => console.log(e));
    }
  }, [selectedOffer]);

  const handleSubmit = () => {
    const payload = { offerId: selectedOffer.id, priceId: selectedPrice.id };
    updateQuote(quoteDetails.id, payload)
      .then((res) => {
        setStep(3);
        setQuoteUpdated((prev) => {
          return !prev;
        });
      })
      .catch((e) => console.log(e));
  };
  return (
    <div className="col-8 d-flex flex-column gap-5">
      <span className="d-flex gap-3 align-items-center">
        <FaArrowLeft
          size={36}
          cursor={"pointer"}
          onClick={() => {
            setStep(1);
          }}
        />
        <h2>Étape 3: Offres</h2>
      </span>
      {!!!selectedOffer?.id ? (
        <div>Aucune offre disponible pour le moment</div>
      ) : (
        <div className="d-flex flex-column gap-3">
          <div className="d-flex justify-content-between">
            <div>
              <span style={{ color: "#009c6a" }}>Devis numéro : </span>
              <span>{quoteDetails.id.slice(0, 8)}</span>
            </div>
            <span>
              <DropdownSB
                style={{ width: "100%" }}
                selectedValue={pricings[0]}
                dataList={pricings}
                onSelectItem={(el) => {
                  setSelectedPrice(el);
                }}
              />
            </span>
          </div>
          <div className="offer-card-container">
            <div className="offer-box col-3">
              <span>{`Offre ${selectedOffer.name}`}</span>
              <span className="d-flex gap-3 justify-content-center align-items-center">
                <span style={{ color: "#3d7d65", fontSize: "36px" }}>
                  {selectedPrice.price + " €"}
                </span>
                <span style={{ color: "#777777" }}>
                  {PriceLabels[selectedPrice.periodType]}
                </span>
              </span>
            </div>
            <div className="col-8 d-flex gap-4 flex-wrap">
              {selectedOffer.rules.map((rule) => {
                let value = "";
                const obj = JSON.parse(rule.value);
                if (
                  rule.propertyType === "Number" ||
                  rule.propertyType === "Date"
                ) {
                  value = `Entre ${obj.minValue} et ${obj.maxValue}`;
                }
                if (
                  rule.propertyType === "String" ||
                  rule.propertyType === "Boolean"
                ) {
                  value = obj.value;
                }
                if (rule.propertyType === "List") {
                  value = obj.selectedValues.join(" - ");
                }
                return (
                  <div className="d-flex flex-column col-3">
                    <span className="title-element">{rule.label}</span>
                    <span>{value}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}

      <span className="d-flex justify-content-end">
        {!!!selectedOffer?.id ? (
          <Button
            styled="primary"
            title="Retourner"
            onClick={() => {
              setStep(1);
            }}
          />
        ) : (
          <Button
            styled="primary"
            title="Procéder au paiement"
            onClick={() => {
              handleSubmit();
            }}
          />
        )}
      </span>
    </div>
  );
};

export default Offers;
