import { config } from "../../config/config";
const getAllContracts = () => {
  return config.axios.get(config.CONTRACT_QUERIES_BASE_URL + "/contract");
};
const getContractDetails = (id) => {
  return config.axios.get(config.ORCHESTRATOR_BASE_URL + "/orchestrator/contracts/" + id);
};
const createContract = (data) => {
  return config.axios.post(
    config.CONTRACT_COMMANDS_BASE_URL + "/contract",
    data
  );
};
const updateContract = (data) => {
  return config.axios.put(
    config.CONTRACT_COMMANDS_BASE_URL + "/contract",
    data
  );
};
const patchContract = (id, data) => {
  return config.axios.patch(
    config.CONTRACT_COMMANDS_BASE_URL + "/contract/" + id,
    data,
    {
      headers: { "Content-Type": "application/json-patch+json" },
    }
  );
};
const getAllContractProperties = () => {
  return config.axios.get(config.CONTRACT_RESSOURCES_BASE_URL + "/properties");
};
const createContractProprety = (data) => {
  return config.axios.post(
    config.CONTRACT_COMMANDS_BASE_URL + "/properties/create-new-property",
    data
  );
};
// TODO
const deleteContractProperty = (id) => {
  return config.axios.delete(
    config.CONTRACT_COMMANDS_BASE_URL + "/properties/" + id
  );
};

const addPropertyToContract = (contractId, data) => {
  return config.axios.post(
    config.CONTRACT_COMMANDS_BASE_URL +
      "/contract/" +
      contractId +
      "/add-property",
    data
  );
};
const updateContractProperty = (contractId, data) => {
  return config.axios.post(
    config.CONTRACT_COMMANDS_BASE_URL +
      "/contract/" +
      contractId +
      "/update-property",
    data
  );
};
const getAllRoles = () => {
  return config.axios.get(config.CONTRACT_RESSOURCES_BASE_URL + "/roles");
};
const createRole = (data) => {
  return config.axios.post(
    config.CONTRACT_RESSOURCES_BASE_URL + "/roles",
    data
  );
};
const addRoleToContract = (contractId, data) => {
  return config.axios.post(
    config.CONTRACT_COMMANDS_BASE_URL + "/contract/" + contractId + "/add-role",
    data
  );
};
export {
  getAllContracts,
  getContractDetails,
  createContract,
  updateContract,
  patchContract,
  getAllContractProperties,
  createContractProprety,
  deleteContractProperty,
  addPropertyToContract,
  updateContractProperty,
  getAllRoles,
  createRole,
  addRoleToContract,
};
