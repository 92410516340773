import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_SUBDOMAIN = process.env.REACT_APP_BASE_SUBDOMAIN;

const axiosInstance = axios.create();
axiosInstance.defaults.headers.common[
  "apiKey"
] = `${process.env.REACT_APP_API_KEY_KONG}`;

const config = {
  axios: axiosInstance,

  KEYCLOAK_BASE_URL: `https://keycloakapp${BASE_SUBDOMAIN}`,

  vehicule_product_id: "268ce0ad-5607-4da2-a2c4-716d7dcded33",

  PERSON_QUERIES_BASE_URL: `${BASE_URL}/person/queries`,
  PERSON_COMMANDS_BASE_URL: `${BASE_URL}/person/commands`,
  PERSON_RESSOURCES_BASE_URL: `${BASE_URL}/person/resources`,

  CONTRACT_QUERIES_BASE_URL: `${BASE_URL}/contract/queries`,
  CONTRACT_COMMANDS_BASE_URL: `${BASE_URL}/contract/commands`,
  CONTRACT_RESSOURCES_BASE_URL: `${BASE_URL}/contract/resources`,

  PRODUCT_QUERIES_BASE_URL: `${BASE_URL}/product/queries`,
  PRODUCT_COMMANDS_BASE_URL: `${BASE_URL}/product/commands`,
  PRODUCT_RESSOURCES_BASE_URL: `${BASE_URL}/product/resources`,

  OFFER_QUERIES_BASE_URL: `${BASE_URL}/offer/queries`,
  OFFER_COMMANDS_BASE_URL: `${BASE_URL}/offer/commands`,
  OFFER_RESSOURCES_BASE_URL: `${BASE_URL}/offer/resources`,

  PRICING_COMMANDS_BASE_URL: `${BASE_URL}/pricing/commands`,
  PRICING_QUERIES_BASE_URL: `${BASE_URL}/pricing/queries`,

  QUOTE_COMMANDS_BASE_URL: `${BASE_URL}/quote/commands`,
  QUOTE_QUERIES_BASE_URL: `${BASE_URL}/quote/queries`,

  AUTH_BASE_URL: `${BASE_URL}/auth`,

  ORCHESTRATOR_BASE_URL: `${BASE_URL}/orchestrator`,
};

export default config;
