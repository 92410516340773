import { useEffect, useState } from "react";
import Input from "../../components/ui/Input";
import Dropdown from "../../components/ui/Dropdown";
import { FiCheck, FiEdit3, FiX } from "react-icons/fi";
import {
  addPropertyToContract,
  getAllContractProperties,
  updateContract,
  updateContractProperty,
} from "./services";
import { formatDate } from "../../utils/utilities";

const ContractInfoCard = ({
  contractDetails,
  setContractUpdated,
  ...props
}) => {
  const [details, setDetails] = useState({});
  const [editDetails, setEditDetails] = useState({
    type: false,
    status: false,
    startDate: false,
    endDate: false,
    country: false,
  });
  const [restProperties, setRestProperties] = useState([]);
  const [contractProperties, setContractProperties] = useState([]);
  const [allProperties, setAllProperties] = useState([]);
  const [error, setError] = useState({});

  /*Details handling Begin*/
  const handleEditDetails = (key, val) => {
    setEditDetails({ ...editDetails, [key]: val });
  };

  const handleDetails = (key, val) => {
    setDetails({ ...details, [key]: val });
  };

  const getDetails = () => {
    setDetails({
      type: contractDetails?.type,
      status: contractDetails?.status,
      startDate: contractDetails?.startDate,
      endDate: contractDetails?.endDate,
      country: contractDetails?.country,
    });
  };

  const handleSubmitDetails = (key) => {
    const data = {
      ...contractDetails,
      ...details,
    };
    updateContract(data)
      .then(() => {
        setContractUpdated((prevState) => {
          return !prevState;
        });
        setEditDetails({ ...editDetails, [key]: false });
      })
      .catch((e) => console.log(e));
  };
  /*Details handling End*/

  const handleAddProperties = (key, prop, val) => {
    const properties = restProperties.map((el) => {
      if (el.key === key) {
        return { ...el, [prop]: val };
      } else {
        return el;
      }
    });
    setRestProperties(properties);
  };

  const handleEditProperties = (key, prop, val) => {
    const properties = contractProperties.map((el) => {
      if (el.key === key) {
        return { ...el, [prop]: val };
      } else {
        return el;
      }
    });
    setContractProperties(properties);
  };

  const getContractProperties = () => {
    let contractprops = [];
    if (!!contractDetails.additionalData) {
      contractprops = Object.entries(contractDetails.additionalData).map(
        ([key, value]) => {
          return {
            key: key,
            label: allProperties.find((el) => el.code === key).label,
            isEdit: false,
            value: value,
            type: typeof value,
            closedValues: Array.isArray(value)
              ? allProperties.find((el) => el.code === key).validationRules
                  .closedValues
              : null,
          };
        }
      );
    }
    return setContractProperties(contractprops.sort((a, b) => a.key < b.key));
  };

  const getMissingProperties = () => {
    getAllContractProperties()
      .then((res) => {
        let missingprops = res.data.map((el) => {
          return {
            key: el.code,
            label: el.label,
            isAdd: false,
            value: "",
            type: el.propertyType,
            closedValues: el?.validationRules?.closedValues || null,
          };
        });
        setAllProperties(res.data);
        if (!!contractDetails.additionalData) {
          missingprops = missingprops.filter(
            (el) =>
              !Object.keys(contractDetails.additionalData).includes(el.key)
          );
        }
        return setRestProperties(missingprops.sort((a, b) => a.key < b.key));
      })
      .catch((e) => console.log(e));
  };

  const handleSubmitAddProperties = (key) => {
    const data = {
      data: {
        [key]: restProperties.find((el) => el.key === key).value,
      },
    };
    addPropertyToContract(contractDetails.id, data)
      .then(() => {
        setContractUpdated((prevState) => {
          return !prevState;
        });
      })
      .catch((e) => {
        setError({ ...error, [key]: e.response.data });
      });
  };

  const handleSubmitEditProperties = (key) => {
    const data = {
      data: {
        [key]: contractProperties.find((el) => el.key === key).value,
      },
    };
    updateContractProperty(contractDetails.id, data)
      .then(() => {
        setContractUpdated((prevState) => {
          return !prevState;
        });
      })
      .catch((e) => {
        setError({ ...error, [key]: e.response.data });
      });
  };

  useEffect(() => {
    getDetails();
    getMissingProperties();
    getContractProperties();
  }, [contractDetails]);

  return (
    <div className="info-card-container d-flex flex-column p-3 gap-3">
      <span className="title-element">Détails du contrat</span>

      <div className="d-flex flex-column gap-2">
        <div className="d-flex flex-column">
          <span className="title-element">Créé le : </span>
          <span>{formatDate(contractDetails.creationDate)}</span>
        </div>
        <div className="d-flex gap-3">
          <div className="d-flex flex-column col-6">
            <span className="title-element">Type : </span>
            {editDetails["type"] ? (
              <div className="d-flex gap-2 align-items-center">
                <Input
                  value={details.type}
                  styled={"filled-input"}
                  setValue={(val) => {
                    handleDetails("type", val);
                  }}
                  errorMsg={error["type"]}
                />

                <span
                  className="icon-btn"
                  onClick={() => {
                    handleSubmitDetails("type");
                  }}
                >
                  <FiCheck />
                </span>
                <span
                  className="icon-btn"
                  onClick={() => {
                    handleEditDetails("type", false);
                  }}
                >
                  <FiX />
                </span>
              </div>
            ) : (
              <div className="d-flex gap-2">
                <span>{details.type}</span>
                <span
                  className="icon-btn"
                  onClick={() => {
                    handleEditDetails("type", true);
                  }}
                >
                  <FiEdit3 />
                </span>
              </div>
            )}
          </div>
          <div className="d-flex flex-column col-6">
            <span className="title-element">Statut : </span>
            {editDetails["status"] ? (
              <div className="d-flex gap-2 align-items-center">
                <Input
                  value={details.status}
                  styled={"filled-input"}
                  setValue={(val) => {
                    handleDetails("status", val);
                  }}
                  errorMsg={error["status"]}
                />

                <span
                  className="icon-btn"
                  onClick={() => {
                    handleSubmitDetails("status");
                  }}
                >
                  <FiCheck />
                </span>
                <span
                  className="icon-btn"
                  onClick={() => {
                    handleEditDetails("status", false);
                  }}
                >
                  <FiX />
                </span>
              </div>
            ) : (
              <div className="d-flex gap-2">
                <span>{details.status}</span>
                <span
                  className="icon-btn"
                  onClick={() => {
                    handleEditDetails("status", true);
                  }}
                >
                  <FiEdit3 />
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="d-flex gap-3">
          <div className="d-flex flex-column col-6">
            <span className="title-element">Commence le : </span>
            {editDetails["startDate"] ? (
              <div className="d-flex gap-2 align-items-center">
                <Input
                  value={details.startDate}
                  styled={"filled-input"}
                  setValue={(val) => {
                    handleDetails("startDate", val);
                  }}
                  errorMsg={error["startDate"]}
                />

                <span
                  className="icon-btn"
                  onClick={() => {
                    handleSubmitDetails("startDate");
                  }}
                >
                  <FiCheck />
                </span>
                <span
                  className="icon-btn"
                  onClick={() => {
                    handleEditDetails("startDate", false);
                  }}
                >
                  <FiX />
                </span>
              </div>
            ) : (
              <div className="d-flex gap-2">
                <span>{details.startDate}</span>
                <span
                  className="icon-btn"
                  onClick={() => {
                    handleEditDetails("startDate", true);
                  }}
                >
                  <FiEdit3 />
                </span>
              </div>
            )}
          </div>
          <div className="d-flex flex-column col-6">
            <span className="title-element">Jusqu'au : </span>
            {editDetails["endDate"] ? (
              <div className="d-flex gap-2 align-items-center">
                <Input
                  value={details.endDate}
                  styled={"filled-input"}
                  setValue={(val) => {
                    handleDetails("endDate", val);
                  }}
                  errorMsg={error["endDate"]}
                />

                <span
                  className="icon-btn"
                  onClick={() => {
                    handleSubmitDetails("endDate");
                  }}
                >
                  <FiCheck />
                </span>
                <span
                  className="icon-btn"
                  onClick={() => {
                    handleEditDetails("endDate", false);
                  }}
                >
                  <FiX />
                </span>
              </div>
            ) : (
              <div className="d-flex gap-2">
                <span>{details.endDate}</span>
                <span
                  className="icon-btn"
                  onClick={() => {
                    handleEditDetails("endDate", true);
                  }}
                >
                  <FiEdit3 />
                </span>
              </div>
            )}
          </div>
        </div>

        <div className="d-flex gap-3">
          <div className="d-flex flex-column col-6">
            <span className="title-element">Pays : </span>
            {editDetails["country"] ? (
              <div className="d-flex gap-2 align-items-center">
                <Input
                  value={details.country}
                  styled={"filled-input"}
                  setValue={(val) => {
                    handleDetails("country", val);
                  }}
                  errorMsg={error["country"]}
                />

                <span
                  className="icon-btn"
                  onClick={() => {
                    handleSubmitDetails("country");
                  }}
                >
                  <FiCheck />
                </span>
                <span
                  className="icon-btn"
                  onClick={() => {
                    handleEditDetails("country", false);
                  }}
                >
                  <FiX />
                </span>
              </div>
            ) : (
              <div className="d-flex gap-2">
                <span>{details.country}</span>
                <span
                  className="icon-btn"
                  onClick={() => {
                    handleEditDetails("country", true);
                  }}
                >
                  <FiEdit3 />
                </span>
              </div>
            )}
          </div>
        </div>

        <span className="title-element mt-5">A propos</span>
        {contractProperties.map(
          ({ key, label, isEdit, value, type, closedValues }) => (
            <div key={key} className="d-flex flex-column">
              <span className="title-element">{label}</span>
              {!!isEdit ? (
                <div className="d-flex gap-2 align-items-center">
                  {Array.isArray(value) ? (
                    <Dropdown
                      value={value[0]}
                      options={closedValues.map((el, i) => {
                        return { id: i, value: el, label: el };
                      })}
                      setValue={(val) => {
                        handleEditProperties(key, "value", [val]);
                      }}
                      errorMsg={error[key]}
                    />
                  ) : (
                    <Input
                      value={value}
                      type={type === "number" && "number"}
                      styled={"filled-input"}
                      setValue={(val) => {
                        handleEditProperties(key, "value", val);
                      }}
                      errorMsg={error[key]}
                    />
                  )}
                  <span
                    className="icon-btn"
                    onClick={() => {
                      handleSubmitEditProperties(key);
                    }}
                  >
                    <FiCheck />
                  </span>
                  <span
                    className="icon-btn"
                    onClick={() => {
                      handleEditProperties(key, "isEdit", false);
                    }}
                  >
                    <FiX />
                  </span>
                </div>
              ) : (
                <div className="d-flex gap-2">
                  <span>{value}</span>
                  <span
                    className="icon-btn"
                    onClick={() => {
                      handleEditProperties(key, "isEdit", true);
                    }}
                  >
                    <FiEdit3 />
                  </span>
                </div>
              )}
            </div>
          )
        )}

        {restProperties.map(
          ({ key, label, isAdd, value, type, closedValues }) => (
            <div key={key} className="d-flex flex-column">
              <span className="title-element">{label}</span>
              {!!isAdd ? (
                <div className="d-flex gap-2 align-items-center">
                  {type === "List" ? (
                    <Dropdown
                      value={value}
                      options={[
                        { id: -1, value: null, label: "Choisir" },
                        ...closedValues.map((el, i) => {
                          return { id: i, value: el, label: el };
                        }),
                      ]}
                      setValue={(val) => {
                        handleAddProperties(key, "value", [val]);
                      }}
                      errorMsg={error[key]}
                    />
                  ) : (
                    <Input
                      styled={"filled-input"}
                      value={value}
                      type={type === "Number" && "number"}
                      setValue={(val) => {
                        handleAddProperties(key, "value", val);
                      }}
                      errorMsg={error[key]}
                    />
                  )}
                  <span
                    className="icon-btn"
                    onClick={() => {
                      handleSubmitAddProperties(key);
                    }}
                  >
                    <FiCheck />
                  </span>
                  <span
                    className="icon-btn"
                    onClick={() => {
                      handleAddProperties(key, "isAdd", false);
                    }}
                  >
                    <FiX />
                  </span>
                </div>
              ) : (
                <span
                  className="relation-link-name"
                  onClick={() => {
                    handleAddProperties(key, "isAdd", true);
                  }}
                >
                  Définir
                </span>
              )}
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ContractInfoCard;
