import React, { useState } from "react";
import Button from "../../components/ui/Button";
import { FiX } from "react-icons/fi";
import Modal from "react-modal";
import "./personnes.scss";
import Input from "../../components/ui/Input";
import Dropdown from "../../components/ui/Dropdown";
import { createPerson } from "./services";

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    padding: "0px",
  },
};

const AddPersonModal = ({
  addPersonModalIsOpen,
  setAddPersonModalIsOpen,
  setUserAdded,
  ...props
}) => {
  const [addPersonData, setAddPersonData] = useState({});

  const handleCloseModal = () => {
    setAddPersonModalIsOpen(false);
    setAddPersonData({});
  };
  const handleSubmit = () => {
    createPerson(addPersonData)
      .then((response) => {
        handleCloseModal();
        setUserAdded((prevState) => {
          return !prevState;
        });
      })
      .catch((e) => console.log(e));
  };
  return (
    <Modal isOpen={addPersonModalIsOpen} style={ModalStyle}>
      <div className="modal-header">
        <h5>Créer une personne</h5>
        <FiX
          size={24}
          style={{ cursor: "pointer" }}
          onClick={handleCloseModal}
        />
      </div>
      <div className="d-flex flex-column p-4 gap-4">
        <Dropdown
          label={"Genre"}
          value={addPersonData.gender}
          options={[
            { id: -1, value: null, label: "Choisir le genre" },
            { id: 1, value: "MALE", label: "Homme" },
            { id: 2, value: "FEMALE", label: "Femme" },
          ]}
          setValue={(value) => {
            setAddPersonData({ ...addPersonData, gender: value });
          }}
        />
        <div className="d-flex gap-5">
          <Input
            label={"Prénom"}
            styled={"filled-input"}
            value={addPersonData.firstName}
            setValue={(value) => {
              setAddPersonData({ ...addPersonData, firstName: value });
            }}
          />{" "}
          <Input
            label={"Nom"}
            styled={"filled-input"}
            value={addPersonData.lastName}
            setValue={(value) => {
              setAddPersonData({ ...addPersonData, lastName: value });
            }}
          />
        </div>
        <div>
          <Input
            label={"Email"}
            styled={"filled-input"}
            value={addPersonData.email}
            setValue={(value) => {
              setAddPersonData({ ...addPersonData, email: value });
            }}
          />
        </div>
        <div className="d-flex justify-content-end gap-3">
          <Button
            title={"Annuler"}
            styled={"secondary"}
            onClick={handleCloseModal}
          />
          <Button
            title={"Créer une personne"}
            styled={"primary"}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddPersonModal;
