import { useEffect, useState } from "react";
import Button from "../../components/ui/Button";
import ProductModal from "./ProductModal";
import { FiPlus } from "react-icons/fi";

const ProductPropertiesCard = ({
  productDetails,
  setProductUpdated,
  ...props
}) => {
  const [propertyList, setPropertyList] = useState([]);
  const [productModalIsOpen, setProductModalIsOpen] = useState(false);
  const [productData, setProductData] = useState(productDetails);

  useEffect(() => {
    if (!!productDetails.properties) {
      const Properties = productDetails.properties.map((el) => {
        return { code: el.code, label: el.label, value: el.propertyType };
      });

      setPropertyList(Properties);
    }
    setProductData(productDetails);
  }, [productDetails]);
  return (
    <>
      <ProductModal
        productData={productData}
        setProductData={setProductData}
        productModalIsOpen={productModalIsOpen}
        setProductModalIsOpen={setProductModalIsOpen}
        setProductAdded={setProductUpdated}
      />
      <div className="info-card-container d-flex flex-column p-3 gap-3">
        <span className="title-element">Propriétés</span>
        {!!propertyList.length ? (
          <div className="d-flex flex-wrap">
            {propertyList.map((el, index) => (
              <div key={index} className={`d-flex flex-column col-4 p-2`}>
                <span className="title-element">{el.label}</span>
                <div className="d-flex gap-2">
                  <span>{el.value}</span>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>Ce produit n'a aucune propriété</div>
        )}
        <hr />
        <div>
          <Button
            title={"Ajouter une propriété"}
            styled={"primary"}
            type={"trailing-icon"}
            icon={<FiPlus />}
            onClick={() => {
              setProductModalIsOpen(true);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ProductPropertiesCard;
