import React, { useEffect, useState } from "react";
import "./properties.scss";
import Button from "../../components/ui/Button";
import { FiPlus } from "react-icons/fi";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { columns } from "./config";
import CreatePropretyModal from "./CreatePropertyModal";

const PropertyList = ({ createProprety, getAllProperties, ...props }) => {
  const [propertiesList, setPropertiesList] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 20 });
  const [createModal, setCreateModal] = useState(false);
  const [propertyAdded, setPropertyAdded] = useState(false);

  useEffect(() => {
    getAllProperties()
      .then((response) => {
        setPropertiesList(response.data);
      })
      .catch((e) => console.log(e));
  }, [propertyAdded, getAllProperties]);
  return (
    <>
      <CreatePropretyModal
        modalIsOpen={createModal}
        setModalIsOpen={setCreateModal}
        setPropertyAdded={setPropertyAdded}
        onCreate={createProprety}
      />
      {propertiesList.length ? (
        <>
          <div className="d-flex flex-row-reverse">
            <Button
              styled="primary"
              title="Créer une propriété"
              type="trailing-icon"
              onClick={() => {
                setCreateModal(true);
              }}
              icon={<FiPlus />}
            />
          </div>

          <div className="flex-row">
            <DataGrid
              rows={propertiesList}
              columns={columns}
              paginationModel={pagination}
              onPaginationModelChange={setPagination}
              getRowHeight={() => "auto"}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 1,
                },
              }}
              disableColumnMenu
              disableColumnResize
              disableColumnSorting
              disableRowSelectionOnClick
              onRowClick={({ id }) => {}}
            />
          </div>
        </>
      ) : (
        <div className=" d-flex flex-column align-items-center gap-4 m-auto">
          <span>Il n’y a actuellement aucune propriété dans la liste</span>
          <span>
            <Button
              styled="primary"
              title="Créer une propriété"
              type="trailing-icon"
              onClick={() => {
                setCreateModal(true);
              }}
              icon={<FiPlus />}
            />
          </span>
        </div>
      )}
    </>
  );
};

export default PropertyList;
