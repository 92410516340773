import { useEffect, useState } from "react";
import { getAllPricingByOffer } from "./services";
import Card from "../../components/ui/Card";
import PricingModal from "./PricingModal";
import { FiEdit3 } from "react-icons/fi";

const OfferPricings = ({ offerDetails, ...props }) => {
  const PricingType = {
    ONESHOT: "Paiement comptant",
    MONTH: "Paiement mensuel",
    YEAR: "Paiement annuel",
  };
  const [pricing, setPricing] = useState([]);
  const [prices, setPrices] = useState([]);
  const [pricingUpdated, setPricingUpdated] = useState(false);
  const [updatePricingModalIsOpen, setUpdatePricingModalIsOpen] =
    useState(false);
  const handleCloseModal = () => {
    setUpdatePricingModalIsOpen(false);
  };
  useEffect(() => {
    if (!!offerDetails?.id) {
      getAllPricingByOffer(offerDetails.id)
        .then(({ data }) => {
          setPrices(data);
          const Pricings = data
            .filter((el) => el.status === "ACTIVE")
            .map((el) => {
              return {
                label: PricingType[el.periodType],
                value: el.price + " " + el.currency,
              };
            });
          setPricing(Pricings);
        })
        .catch((e) => console.log(e));
    }
  }, [offerDetails, pricingUpdated]);

  return (
    <>
      <PricingModal
        updatePricingModalIsOpen={updatePricingModalIsOpen}
        setUpdatePricingModalIsOpen={setUpdatePricingModalIsOpen}
        handleCloseModal={handleCloseModal}
        setPricingUpdated={setPricingUpdated}
        prices={prices}
        offerId={offerDetails.id}
      />
      <Card
        data={pricing}
        columns={1}
        title={"Tarification"}
        icon={<FiEdit3 cursor={"pointer"} />}
        onClickIcon={() => setUpdatePricingModalIsOpen(true)}
      />
    </>
  );
};

export default OfferPricings;
