import React, { useEffect, useState } from "react";
import { FiCheck, FiEdit3, FiX } from "react-icons/fi";
import Input from "../../components/ui/Input";
import Dropdown from "../../components/ui/Dropdown";
import { updatePerson } from "./services";

const PersonDetailsHeader = ({
  personDetails,
  setPersonUpdated,
  navigate,
  ...props
}) => {
  const [fields, setFields] = useState({});
  const [editFields, setEditFields] = useState({
    fullname: false,
    email: false,
  });

  const handleEditFields = (key, val) => {
    setEditFields({ ...editFields, [key]: val });
  };

  const handleFields = (key, val) => {
    setFields({ ...fields, [key]: val });
  };

  const getFields = () => {
    setFields({
      gender: personDetails?.gender,
      firstName: personDetails?.firstName,
      lastName: personDetails?.lastName,
      email: personDetails?.email,
    });
  };

  const handleSubmit = (key) => {
    const data = { id: personDetails.id, ...fields };
    updatePerson(data)
      .then(() => {
        setPersonUpdated((prevState) => {
          return !prevState;
        });
        setEditFields({ ...editFields, [key]: false });
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getFields();
  }, [personDetails]);

  return (
    <div className="details-header d-flex flex-column px-5 py-4 gap-5">
      <div className="d-flex gap-2">
        <span
          className="pathlink-item"
          onClick={() => {
            navigate("/persons");
          }}
        >
          {"Personnes >"}
        </span>
        <span>
          {personDetails.firstName?.concat(" ", personDetails.lastName)}
        </span>
      </div>
      <div className="d-flex flex-column gap-2">
        <div>
          <span className="title-element">Référence : </span>
          <span>{personDetails.id?.slice(0, 8)}</span>
        </div>
        {editFields["fullname"] ? (
          <div className="d-flex gap-2 align-items-center">
            <Dropdown
              value={fields.gender}
              options={[
                { id: 1, value: "MALE", label: "Homme" },
                { id: 2, value: "FEMALE", label: "Femme" },
              ]}
              setValue={(val) => {
                handleFields("gender", val);
              }}
            />
            <Input
              styled={"filled-input"}
              value={fields.firstName}
              setValue={(val) => {
                handleFields("firstName", val);
              }}
            />
            <Input
              styled={"filled-input"}
              value={fields.lastName}
              setValue={(val) => {
                handleFields("lastName", val);
              }}
            />
            <span
              className="icon-btn"
              onClick={() => {
                handleSubmit("fullname");
              }}
            >
              <FiCheck />
            </span>
            <span
              className="icon-btn"
              onClick={() => {
                handleEditFields("fullname", false);
              }}
            >
              <FiX />
            </span>
          </div>
        ) : (
          <div className="d-flex gap-2 align-items-center">
            <span className="fs-5">
              {personDetails?.gender === "MALE" ? "Mr " : "Mme "}
              {personDetails.firstName?.concat(" ", personDetails.lastName)}
            </span>
            <span
              className="icon-btn"
              onClick={() => {
                handleEditFields("fullname", true);
              }}
            >
              <FiEdit3 />
            </span>
          </div>
        )}
        <div className="d-flex gap-2 align-items-center">
          <span className="title-element">Email : </span>
          {editFields["email"] ? (
            <>
              <Input
                styled={"filled-input"}
                value={fields.email}
                setValue={(val) => {
                  handleFields("email", val);
                }}
              />
              <span
                className="icon-btn"
                onClick={() => {
                  handleSubmit("email");
                }}
              >
                <FiCheck />
              </span>
              <span
                className="icon-btn"
                onClick={() => {
                  handleEditFields("email", false);
                }}
              >
                <FiX />
              </span>
            </>
          ) : (
            <>
              <span>{personDetails.email}</span>
              <span
                className="icon-btn"
                onClick={() => {
                  handleEditFields("email", true);
                }}
              >
                <FiEdit3 />
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonDetailsHeader;
