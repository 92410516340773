import React, { useEffect, useState } from "react";
import Button from "../../../../components/ui/Button";
import Input from "../../../../components/ui/Input";
import Dropdown from "../../../../components/ui/Dropdown";
import {
  addPropertyToPerson,
  createPerson,
  getPersonDetails,
  updatePerson,
} from "../../../personne/services";
import { updateQuote } from "../services";

const PersonalInfo = ({
  setStep,
  quoteId,
  quoteDetails,
  setQuoteUpdated,
  ...props
}) => {
  const [personalData, setPersonalData] = useState({ gender: "MALE" });
  const [additionalData, setAdditionalData] = useState({});

  const handleSubmit = () => {
    !quoteDetails.personId
      ? createPerson(personalData)
          .then((resp) => {
            addPropertyToPerson(resp.data, { data: additionalData });
            updateQuote(quoteId, { personId: resp.data }).then(() => {
              setStep(1);
              setQuoteUpdated((prev) => {
                return !prev;
              });
            });
          })
          .catch((e) => console.log(e))
      : updatePerson({
          ...personalData,
          additionalData: additionalData,
        })
          .then((res) => {
            setStep(1);
          })
          .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (!!quoteDetails.personId) {
      getPersonDetails(quoteDetails.personId)
        .then((res) => {
          setPersonalData(res.data);
          setAdditionalData(res.data.additionalData || {});
        })
        .catch((e) => console.log(e));
    }
  }, [quoteDetails.personId]);

  return (
    <div className="d-flex flex-column gap-5">
      <span className="d-flex gap-3 align-items-center">
        <h2>Étape 1: informations tiers</h2>
      </span>
      <div className="col-8 d-flex flex-column gap-2">
        <div className="d-flex flex-column">
          <h5>Informations personnelles</h5>
          <hr></hr>
          <div className="d-flex flex-column gap-4">
            <div className="col-5">
              <Dropdown
                value={personalData.gender}
                options={[
                  { id: 1, value: "MALE", label: "Homme" },
                  { id: 2, value: "FEMALE", label: "Femme" },
                ]}
                setValue={(value) => {
                  setPersonalData({ ...personalData, gender: value });
                }}
              />
            </div>

            <div className="d-flex justify-content-between">
              <div className="col-5 d-flex align-items-end">
                <Input
                  style={{ width: "100%" }}
                  label={"Prénom"}
                  placeholder={"Prénom"}
                  styled={"filled-input"}
                  value={personalData.firstName}
                  setValue={(value) => {
                    setPersonalData({ ...personalData, firstName: value });
                  }}
                />
              </div>
              <div className="col-5">
                <Input
                  style={{ width: "100%" }}
                  label={"Nom"}
                  placeholder={"Nom"}
                  styled={"filled-input"}
                  value={personalData.lastName}
                  setValue={(value) => {
                    setPersonalData({ ...personalData, lastName: value });
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-5">
                <Input
                  style={{ width: "100%" }}
                  label={"Mail"}
                  placeholder={"Mail"}
                  styled={"filled-input"}
                  value={personalData.email}
                  setValue={(value) => {
                    setPersonalData({ ...personalData, email: value });
                  }}
                />
              </div>
              <div className="col-5">
                <Input
                  style={{ width: "100%" }}
                  label={"Numéro de téléphone"}
                  placeholder={"Numéro de téléphone"}
                  styled={"filled-input"}
                  value={additionalData.phoneNumber}
                  setValue={(value) => {
                    setAdditionalData({
                      ...additionalData,
                      phoneNumber: value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-5">
                <Input
                  style={{ width: "100%" }}
                  label={"Adresse"}
                  placeholder={"Adresse"}
                  styled={"filled-input"}
                  value={additionalData.address}
                  setValue={(value) => {
                    setAdditionalData({ ...additionalData, address: value });
                  }}
                />
              </div>
              <div className="col-5">
                <Input
                  style={{ width: "100%" }}
                  label={"Code postal"}
                  type={"number"}
                  placeholder={"Code postal"}
                  styled={"filled-input"}
                  value={additionalData.postalCode}
                  setValue={(value) => {
                    setAdditionalData({ ...additionalData, postalCode: value });
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-5">
                <Input
                  style={{ width: "100%" }}
                  label={"Date de naissance"}
                  placeholder={"Date de naissance"}
                  styled={"filled-input"}
                  value={additionalData.birthdate}
                  setValue={(value) => {
                    setAdditionalData({ ...additionalData, birthdate: value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column mt-3">
          <h5>Informations permis</h5>
          <hr></hr>
          <div className="d-flex flex-column gap-4">
            <div className="d-flex justify-content-between">
              <div className="col-5">
                <Input
                  style={{ width: "100%" }}
                  label={"Numéro de permis"}
                  placeholder={"Numéro de permis"}
                  styled={"filled-input"}
                  value={additionalData.licenseNumber}
                  setValue={(value) => {
                    setAdditionalData({
                      ...additionalData,
                      licenseNumber: value,
                    });
                  }}
                />
              </div>
              <div className="col-5">
                <Input
                  style={{ width: "100%" }}
                  label={"Date d'obtention du permis"}
                  placeholder={"Date d'obtention du permis"}
                  styled={"filled-input"}
                  value={additionalData.licenseDate}
                  setValue={(value) => {
                    setAdditionalData({
                      ...additionalData,
                      licenseDate: value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className="col-5">
                <Input
                  style={{ width: "100%" }}
                  label={"Lieu d’obtention du permis"}
                  placeholder={"Lieu d’obtention du permis"}
                  styled={"filled-input"}
                  value={additionalData.licensePlace}
                  setValue={(value) => {
                    setAdditionalData({
                      ...additionalData,
                      licensePlace: value,
                    });
                  }}
                />
              </div>
            </div>
            <hr />
          </div>
        </div>

        <span className="d-flex justify-content-end">
          <Button
            styled="primary"
            title="Enregistrer et continuer"
            onClick={() => {
              handleSubmit();
            }}
          />
        </span>
      </div>
    </div>
  );
};

export default PersonalInfo;
