import React, { useEffect, useState } from "react";
import Button from "../../components/ui/Button";
import { FiX } from "react-icons/fi";
import Modal from "react-modal";
import Dropdown from "../../components/ui/Dropdown";
import { addRoleToContract, getAllRoles } from "./services";
import { getAllPersons } from "../personne/services";
import { getAllCompanies } from "../companies/services";

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    padding: "0px",
  },
};

const AddRoleModal = ({
  contractId,
  setContractUpdated,
  addRoleModalIsOpen,
  setAddRoleModalIsOpen,
  ...props
}) => {
  const [addRoleData, setAddRoleData] = useState({});
  const [allRoles, setAllRoles] = useState([]);
  const [toPersonType, setToPersonType] = useState("company");
  const [allPersons, setAllPersons] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);

  const handleCloseModal = () => {
    setAddRoleModalIsOpen(false);
    setAddRoleData({});
  };
  const getRoles = () => {
    getAllRoles()
      .then((response) => {
        const AllRoles = response.data.map((el) => {
          return { id: el.id, value: el.code, label: el.label };
        });
        setAllRoles([
          { id: null, value: null, label: "Aucun rôle selectionné" },
          ...AllRoles,
        ]);
      })
      .catch((e) => console.log(e));
  };

  const getActors = () => {
    getAllCompanies()
      .then((response) => {
        const AllCompanies = response.data.map((el) => {
          return {
            id: el.id,
            value: el.id,
            label: el.name,
          };
        });
        setAllCompanies([
          { id: null, value: null, label: "Aucune entreprise selectionnée" },
          ...AllCompanies,
        ]);
      })
      .catch((e) => console.log(e));
    getAllPersons()
      .then((response) => {
        const AllPersons = response.data.map((el) => {
          return {
            id: el.id,
            value: el.id,
            label: el.firstName + " " + el.lastName,
          };
        });
        setAllPersons([
          { id: null, value: null, label: "Aucune personne selectionnée" },
          ...AllPersons,
        ]);
      })
      .catch((e) => console.log(e));
  };

  const handleSubmit = () => {
    const data = {
      ...addRoleData,
      personType: toPersonType === "person" ? "NP" : "LP",
    };
    addRoleToContract(contractId, data)
      .then((response) => {
        setContractUpdated((prevState) => {
          return !prevState;
        });
        handleCloseModal();
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getRoles();
    getActors();
  }, []);
  return (
    <Modal isOpen={addRoleModalIsOpen} style={ModalStyle}>
      <div className="modal-header">
        <h5>Ajouter un rôle</h5>
        <FiX
          size={24}
          style={{ cursor: "pointer" }}
          onClick={handleCloseModal}
        />
      </div>
      <div className="d-flex flex-column p-4 gap-4">
        <Dropdown
          label={"Type de rôle"}
          value={addRoleData.roleName}
          options={allRoles}
          setValue={(value) => {
            setAddRoleData({ ...addRoleData, roleName: value });
          }}
        />
        <span>Avec:</span>
        <div className="d-flex gap-4">
          <span
            style={{
              color: toPersonType === "company" && "#009c6a",
              cursor: "pointer",
            }}
            onClick={() => setToPersonType("company")}
          >
            Entreprise
          </span>
          <span
            style={{
              color: toPersonType === "person" && "#009c6a",
              cursor: "pointer",
            }}
            onClick={() => setToPersonType("person")}
          >
            Personne
          </span>
        </div>
        <Dropdown
          value={addRoleData.personId}
          options={toPersonType === "company" ? allCompanies : allPersons}
          setValue={(value) => {
            setAddRoleData({ ...addRoleData, personId: value });
          }}
        />

        <div className="d-flex justify-content-end gap-3">
          <Button
            title={"Annuler"}
            styled={"secondary"}
            onClick={handleCloseModal}
          />
          <Button
            title={"Ajouter un rôle"}
            styled={"primary"}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddRoleModal;
