import { config } from "../../config/config";

const getAllPersons = () => {
  return config.axios.get(config.PERSON_QUERIES_BASE_URL + "/natural-person");
};
const getPersonDetails = (id) => {
  return config.axios.get(
    config.PERSON_QUERIES_BASE_URL + "/natural-person/" + id
  );
};
const createPerson = (data) => {
  return config.axios.post(
    config.PERSON_COMMANDS_BASE_URL + "/natural-person",
    data
  );
};
const updatePerson = (data) => {
  return config.axios.put(
    config.PERSON_COMMANDS_BASE_URL + "/natural-person",
    data
  );
};
const getAllPersonProperties = () => {
  return config.axios.get(
    config.PERSON_RESSOURCES_BASE_URL +
      "/properties-by-person-type?personType=NP"
  );
};
const createPersonProprety = (data) => {
  return config.axios.post(
    config.PERSON_COMMANDS_BASE_URL + "/properties/create-new-property",
    { personType: "NP", ...data }
  );
};
// TODO
const deletePersonProperty = (id) => {
  return config.axios.delete(
    config.PERSON_COMMANDS_BASE_URL + "/properties/NP/" + id
  );
};

const addPropertyToPerson = (personId, data) => {
  return config.axios.post(
    config.PERSON_COMMANDS_BASE_URL +
      "/natural-person/" +
      personId +
      "/add-property",
    data
  );
};
const updatePropertyToPerson = (personId, data) => {
  return config.axios.post(
    config.PERSON_COMMANDS_BASE_URL +
      "/natural-person/" +
      personId +
      "/update-property",
    data
  );
};
const addRelationToPerson = (personId, data) => {
  return config.axios.post(
    config.PERSON_COMMANDS_BASE_URL +
      "/natural-person/" +
      personId +
      "/add-relation",
    data
  );
};
export {
  getAllPersons,
  getPersonDetails,
  createPerson,
  updatePerson,
  getAllPersonProperties,
  createPersonProprety,
  deletePersonProperty,
  addPropertyToPerson,
  updatePropertyToPerson,
  addRelationToPerson,
};
