import React, { FC, useState } from 'react';
import Card from '@mui/material/Card';
import Input from "../../components/ui/Input";
import Button from '../../components/ui/Button';
import GetKeycloakInstance from '../../utils/keycloak';
import { register_user, set_password } from './service';


interface RegisterProps { }

const Register: FC<RegisterProps> = () => {

  const [givenName, setGivenName] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const onButtonClick = async () => {

    // Set initial error values to empty
    setEmailError('')
    setPasswordError('')


    if (process.env.REACT_APP_AUTH === "keycloak") {

      const keycloak = (await GetKeycloakInstance());

      console.log('Keycloak register', JSON.stringify(keycloak))

      return keycloak?.register()//{ redirectUri: config.KEYCLOAK_BASE_URL });

    }

    // Check if the user has entered both fields correctly
    if ('' === email) {
      setEmailError('Please enter your email')
      return
    }

    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,6}$/.test(email)) {
      setEmailError('Please enter a valid email')
      return
    }

    if ('' === password) {
      setPasswordError('Please enter a password')
      return
    }

    if (password.length < 8) {
      setPasswordError('The password must be 8 characters or longer')
      return
    }


    /*
        {
      "username": "john.doe",
      "email": "john.doe@example.com",
      "firstName": "John",
      "lastName": "Doe",
      "attributes":{},
      "groups":[],
    }
          */
    //let headers = new Headers();
    //headers.append('Access-Control-Allow-Origin', 'http://localhost:3000');
    //'{"realm":"Realm-name","id":"Realm-id","enabled":"true"}'

    if (process.env.REACT_APP_AUTH === "keycloak-iosis") {

      const user_payload = {
        "firstName": givenName,
        "lastName": familyName,
        "username": email,
        "email": email,
        "enabled": true
      }

      const password_payload = {
        "type": "password",
        "value": password, // FIXME crypt
        "temporary": false
      }

      register_user(user_payload)
        .then(function (response: any) {
          console.log('Keycloak register response', JSON.stringify(response))

          if (response.status === 201) {

            const user_endpoint = response.headers.location;
            console.log('Keycloak this user_endpoint', user_endpoint)

            set_password(user_endpoint, password_payload)
              .then(function (response: any) {
                console.log('Keycloak register set password response', JSON.stringify(response))

              })
              .catch(function (error: any) {
                console.log(error);
              });
            //props.showError(null)
          }
          else {
            //props.showError("Username does not exists");
          }

        })
        .catch(function (error: any) {
          console.log(error);
        });

    } else if (process.env.REACT_APP_AUTH === "iosis") {
      // TODO
    }

  }


  return (
    <div data-testid="Register" className="container">
      <br />
      <Card className="col-12 m-2">
        <div className="{'titleContainer'} mx-5 mt-3">
          <h6>Ajouter utilisateur à Keycloak Iosis</h6>
        </div>
        <br />
        <div className="{'inputContainer'} mx-5">
          <Input
            style={{ width: "100%" }}
            value={givenName}
            label={"Prénom"}
            placeholder="Enter your given name here"
            setValue={(value: React.SetStateAction<string>) => {
              setGivenName(value)
            }}
            styled={"filled-input"}
          //errorMsg={}
          />
        </div>
        <br />
        <div className="{'inputContainer'} mx-5">
          <Input
            style={{ width: "100%" }}
            value={familyName}
            label={"Nom"}
            placeholder="Enter your family name here"
            setValue={(value: React.SetStateAction<string>) => {
              setFamilyName(value)
            }}
            styled={"filled-input"}
          //errorMsg={}
          />
        </div>
        <br />
        <div className="{'inputContainer'} mx-5">
          <Input
            style={{ width: "100%" }}
            value={email}
            label={"E-mail"}
            placeholder="Enter your email here"
            setValue={(value: React.SetStateAction<string>) => {
              setEmail(value)
            }}
            styled={"filled-input"}
            errorMsg={emailError}
          />
        </div>
        <br />
        <div className="{'inputContainer'} mx-5">
          <Input
            type={"password"}
            style={{ width: "100%" }}
            value={password}
            label={"Mot de passe"}
            placeholder="Enter your password here"
            setValue={(value: React.SetStateAction<string>) => {
              setPassword(value)
            }}
            //onChange={(ev: { target: { value: React.SetStateAction<string>; }; }) => setPassword(ev.target.value)}
            styled={"filled-input"}
            errorMsg={passwordError}
          />
        </div>
        <br />
        <div className="{'buttonContainer'} mx-5 mb-3">
          <Button styled={'primary'} onClick={onButtonClick} title={'Enregistrer'} />
        </div>
      </Card>
    </div>
  )
}

export default Register;
