import React, { FC, useEffect, useState } from "react";
import { getCustomersCount } from "./services";

interface CustomersCardProps {
}

const CustomersCard: FC<CustomersCardProps> = (props) => {

  const [number, setNumber] = useState(0);

  useEffect(() => {
    getCustomersCount()
      .then((res: any) => {
        console.log(res);
        setNumber(res.data);
      })
      .catch((e: any) => console.error(e));
  }, []);

  return (
    <div data-testid="CustomersCard">
      <div className="d-flex flex-column m-3">
        <div className="d-flex mb-3">
          <h6>Nombre de clients</h6>
        </div>
        <div className="d-flex mb-3">
          <h1>{number}</h1>
        </div>
      </div>
    </div>
  );
};

export default CustomersCard;
