import Personnes from "../../pages/personne/Personnes";
import Properties from "../../pages/properties/Properties";
import Relations from "../../pages/relations/Relations";
import Roles from "../../pages/roles/Roles";
import PersonDetails from "../../pages/personne/PersonDetails";
import Contracts from "../../pages/contrat/Contracts";
import ContractDetails from "../../pages/contrat/ContractDetails";
import Companies from "../../pages/companies/Companies";
import CompanyDetails from "../../pages/companies/CompanyDetails";
import Products from "../../pages/products/Products";
import ProductDetails from "../../pages/products/ProductDetails";
import Offers from "../../pages/offers/Offers";
import OfferDetails from "../../pages/offers/OfferDetails";
import Dashboard from "../../pages/Dashboard/Dashboard";
import QuoteAutoList from "../../pages/quotes/automobile/QuoteAutoList";

export const SidebarData = [
  {
    title: "Tableau de bord",
    path: "/",
    component: Dashboard,
    detailsPath: "/dashboard",
    detailsComponent: Dashboard,
  },
  {
    title: "Acteurs",
    isOpen: true,
    subItems: [
      {
        title: "Personnes",
        path: "/persons",
        component: Personnes,
        detailsPath: "/persons/:id",
        detailsComponent: PersonDetails,
      },
      {
        title: "Entreprises",
        path: "/companies",
        component: Companies,
        detailsPath: "/companies/:id",
        detailsComponent: CompanyDetails,
      },
    ],
  },
  {
    title: "Contrats",
    path: "/contracts",
    component: Contracts,
    detailsPath: "/contracts/:id",
    detailsComponent: ContractDetails,
  },
  {
    title: "Produits",
    path: "/products",
    component: Products,
    detailsPath: "/products/:id",
    detailsComponent: ProductDetails,
  },

  {
    title: "Offres",
    path: "/offers",
    component: Offers,
    detailsPath: "/offers/:id",
    detailsComponent: OfferDetails,
  },
  {
    title: "Devis",
    isOpen: false,
    subItems: [
      {
        title: "Automobile",
        path: "/quote_automobile",
        component: QuoteAutoList,
        detailsPath: "/quote_automobile",
        detailsComponent: QuoteAutoList,
      },
    ],
  },
];

export const Settings = [
  {
    title: "Propriétés",
    path: "/properties",
    component: Properties,
  },
  {
    title: "Relations",
    path: "/relations",
    component: Relations,
  },
  {
    title: "Rôles",
    path: "/roles",
    component: Roles,
  },
];
