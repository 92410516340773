import { useEffect, useState } from "react";
import Input from "../../components/ui/Input";
import { FiCheck, FiEdit3, FiX } from "react-icons/fi";

import { formatDate } from "../../utils/utilities";
import { updateProduct } from "./services";

const ProductInfoCard = ({ productDetails, setProductUpdated, ...props }) => {
  const [details, setDetails] = useState({});
  const [editDetails, setEditDetails] = useState({
    creationDate: false,
    lastUpdate: false,
    status: false,
    startDate: false,
    description: false,
  });
  const [error, setError] = useState({});

  /*Details handling Begin*/
  const handleEditDetails = (key, val) => {
    setEditDetails({ ...editDetails, [key]: val });
  };

  const handleDetails = (key, val) => {
    setDetails({ ...details, [key]: val });
  };

  const getDetails = () => {
    setDetails({
      creationDate: productDetails?.creationDate,
      lastUpdate: productDetails?.lastUpdate,
      status: productDetails?.status,
      startDate: productDetails?.since,
      description: productDetails?.description,
    });
  };

  const handleSubmitDetails = (key) => {
    const data = {
      ...productDetails,
      ...details,
    };
    updateProduct(data)
      .then(() => {
        setProductUpdated((prevState) => {
          return !prevState;
        });
        setEditDetails({ ...editDetails, [key]: false });
      })
      .catch((e) => console.log(e));
  };
  /*Details handling End*/

  useEffect(() => {
    getDetails();
  }, [productDetails]);

  return (
    <div className="info-card-container d-flex flex-column p-3 gap-3">
      <span className="title-element">Détails du produit</span>

      <div className="d-flex flex-column gap-2">
        <div className="d-flex gap-3">
          <div className="d-flex flex-column col-6">
            <span className="title-element">Créé le : </span>
            <span>{formatDate(productDetails.creationDate)}</span>
          </div>

          <div className="d-flex flex-column col-6">
            <span className="title-element">Mis à jour le : </span>
            <span>{formatDate(productDetails.lastUpdate)}</span>
          </div>
        </div>
      </div>

      <div className="d-flex gap-3">
        <div className="d-flex flex-column">
          <span className="title-element">Description : </span>
          {editDetails["description"] ? (
            <div className="d-flex gap-2 align-items-center">
              <Input
                value={details.description}
                styled={"filled-input"}
                type={"textArea"}
                setValue={(val) => {
                  handleDetails("description", val);
                }}
                errorMsg={error["description"]}
              />

              <span
                className="icon-btn"
                onClick={() => {
                  handleSubmitDetails("description");
                }}
              >
                <FiCheck />
              </span>
              <span
                className="icon-btn"
                onClick={() => {
                  handleEditDetails("description", false);
                }}
              >
                <FiX />
              </span>
            </div>
          ) : (
            <div className="d-flex gap-2">
              <span>{details.description}</span>
              <span
                className="icon-btn"
                onClick={() => {
                  handleEditDetails("description", true);
                }}
              >
                <FiEdit3 />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductInfoCard;
