import Button from "../../components/ui/Button";
import { FiX } from "react-icons/fi";
import Modal from "react-modal";
import {
  deleteProduct
} from "./services";

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "650px",
    padding: "0px",
    height: "250px",
    overflow: "visible",
  },
};

const ProductArchive = ({
  productId,
  setProductId,
  productArchiveIsOpen,
  setProductArchiveIsOpen,
  setProductArchived,
  ...props
}) => {

  const handleCloseModal = () => {
    setProductArchiveIsOpen(false);
    setProductId({});
  };
  const handleSubmit = () => {
    productId
      ? deleteProduct(productId)
        .then((resp) => {
          handleCloseModal();
          setProductArchived((prevState) => {
            return !prevState;
          });
        })
        .catch((e) => console.log(e))
      : console.log("Archive : empty product Id")
  };
  return (
    <Modal isOpen={productArchiveIsOpen} style={ModalStyle}>
      <div className="modal-header">
        <h5>
          Archiver le produit
        </h5>
        <FiX
          size={24}
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleCloseModal();
          }}
        />
      </div>
      <div className="d-flex flex-column p-4 gap-3">
        <div className="d-flex flex-column gap-4">
          <span>Si vous cliquez sur "Archiver", ceci entraînera l'archivage définitif du produit.</span>
        </div>
        <hr></hr>
        <div className="d-flex justify-content-end gap-3">
          <Button
            title={"Annuler"}
            styled={"secondary"}
            onClick={() => handleCloseModal()}
          />
          <Button
            title={"Archiver"}
            styled={"primary"}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ProductArchive;
