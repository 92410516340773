import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PersonDetailsHeader from "./PersonDetailsHeader";
import PersonInfoCard from "./PersonInfoCard";
import LegalPersonRelationsCard from "../relations/LegalPersonRelationsCard";
import NaturalPersonRelationsCard from "../relations/NaturalPersonRelationsCard";
import { getPersonDetails } from "./services";

const PersonDetails = () => {
  const [personDetails, setPersonDetails] = useState({});
  const [personUpdated, setPersonUpdated] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getPersonDetails(id)
      .then((response) => {
        setPersonDetails(response.data);
      })
      .catch((e) => console.log(e));
  }, [id, personUpdated]);

  return (
    <div className="d-flex flex-column w-100">
      <PersonDetailsHeader
        personDetails={personDetails}
        setPersonUpdated={setPersonUpdated}
        navigate={navigate}
      />
      <div className="d-flex m-5 gap-5">
        <div className="col-3">
          <PersonInfoCard
            personDetails={personDetails}
            setPersonUpdated={setPersonUpdated}
          />
        </div>
        <div className="col-8 d-flex flex-column gap-5">
          <NaturalPersonRelationsCard
            personDetails={personDetails}
            personType={"NP"}
            setPersonUpdated={setPersonUpdated}
            navigate={navigate}
          />
          <LegalPersonRelationsCard
            personDetails={personDetails}
            personType={"NP"}
            setPersonUpdated={setPersonUpdated}
            navigate={navigate}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonDetails;
