import React, { useEffect, useState } from "react";
import { getAllProducts } from "./services";
import Button from "../../components/ui/Button";
import { FiPlus } from "react-icons/fi";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import ProductModal from "./ProductModal";
import ProductArchive from "./ProductArchive";
import { formatDate } from "../../utils/utilities";

const Products = () => {
  const [productList, setProductList] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 20 });
  const [productModalIsOpen, setProductModalIsOpen] = useState(false);
  const [productArchiveIsOpen, setProductArchiveIsOpen] = useState(false);
  const [productAdded, setProductAdded] = useState(false);
  const [productArchived, setProductArchived] = useState(false);
  const [productData, setProductData] = useState({});
  const [productId, setProductId] = useState({});

  const navigate = useNavigate();

  const ActionCell = (params) => {
    return (
      <div>
        <MdModeEdit
          className="mx-2"
          style={{ cursor: "pointer" }}
          size={24}
          onClick={(event) => {
            event.stopPropagation();
            setProductModalIsOpen(true);
            setProductData(params.row);
          }}
        />

        <MdDelete
          className="mx-2"
          style={{ cursor: "pointer" }}
          size={24}
          onClick={(event) => {
            event.stopPropagation();
            setProductArchiveIsOpen(true);
            setProductId(params.row?.id);
          }}
          visibility={params.row?.status === "ARCHIVED" ? "hidden" : "visible"}
        />
      </div>
    );
  };
  const columns = [
    {
      field: "id",
      headerName: "Référence",
      valueGetter: (value) => `${value.slice(0, 8)}`,
      flex: 1,
    },

    {
      field: "name",
      headerName: "Nom du produit",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "creationDate",
      headerName: "Créé le",
      valueGetter: (value) => {
        return formatDate(value);
      },
      flex: 1,
    },
    {
      field: "lastUpdate",
      headerName: "Mis à jour le",
      valueGetter: (value) => {
        return formatDate(value);
      },
      flex: 1,
    },
    {
      field: "status",
      headerName: "Statut",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: ActionCell,
      flex: 1,
    },
  ];

  useEffect(() => {
    getAllProducts()
      .then((res) => {
        setProductList(res.data);
      })
      .catch((e) => console.log(e));
  }, [productAdded, productArchived]);
  return (
    <>
      <ProductModal
        productData={productData}
        setProductData={setProductData}
        productModalIsOpen={productModalIsOpen}
        setProductModalIsOpen={setProductModalIsOpen}
        setProductAdded={setProductAdded}
      />
      <ProductArchive
        productId={productId}
        setProductId={setProductId}
        productArchiveIsOpen={productArchiveIsOpen}
        setProductArchiveIsOpen={setProductArchiveIsOpen}
        setProductArchived={setProductArchived}
      />
      <div className="d-flex flex-column gap-5 m-5 w-100">
        <div className="flex-row fs-4">Produits</div>
        {productList.length ? (
          <>
            <div className="d-flex flex-row-reverse">
              <Button
                styled="primary"
                title="Créer un produit"
                type="trailing-icon"
                onClick={() => {
                  setProductModalIsOpen(true);
                }}
                icon={<FiPlus />}
              />
            </div>
            <div className="flex-row">
              <DataGrid
                rows={productList}
                columns={columns}
                paginationModel={pagination}
                onPaginationModelChange={setPagination}
                disableColumnMenu
                disableColumnResize
                disableColumnSorting
                disableRowSelectionOnClick
                onRowClick={({ id }) => {
                  navigate(`/products/${id}`);
                }}
              ></DataGrid>
            </div>
          </>
        ) : (
          <div className=" d-flex flex-column align-items-center gap-4 m-auto">
            <span>Il n’y a actuellement aucun produit dans la liste</span>
            <span>
              <Button
                styled="primary"
                title="Créer un produit"
                type="trailing-icon"
                onClick={() => {
                  setProductModalIsOpen(true);
                }}
                icon={<FiPlus />}
              />
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default Products;
