import React, { useState } from "react";
import Button from "../../components/ui/Button";
import { FiX } from "react-icons/fi";
import Modal from "react-modal";
import "./personnes.scss";
import Input from "../../components/ui/Input";
import { createCompany } from "./services";

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    padding: "0px",
  },
};

const AddCompanyModal = ({
  addCompanyModalIsOpen,
  setAddCompanyModalIsOpen,
  setCompanyAdded,
  ...props
}) => {
  const [addCompanyData, setAddCompanyData] = useState({});

  const handleCloseModal = () => {
    setAddCompanyModalIsOpen(false);
    setAddCompanyData({});
  };
  const handleSubmit = () => {
    createCompany(addCompanyData)
      .then((response) => {
        handleCloseModal();
        setCompanyAdded((prevState) => {
          return !prevState;
        });
      })
      .catch((e) => console.log(e));
  };
  return (
    <Modal isOpen={addCompanyModalIsOpen} style={ModalStyle}>
      <div className="modal-header">
        <h5>Créer une entreprise</h5>
        <FiX
          size={24}
          style={{ cursor: "pointer" }}
          onClick={handleCloseModal}
        />
      </div>
      <div className="d-flex flex-column p-4 gap-4">
        <Input
          label={"Nom"}
          styled={"filled-input"}
          value={addCompanyData.name}
          setValue={(value) => {
            setAddCompanyData({ ...addCompanyData, name: value });
          }}
        />{" "}
        <Input
          label={"Code"}
          styled={"filled-input"}
          value={addCompanyData.code}
          setValue={(value) => {
            setAddCompanyData({ ...addCompanyData, code: value });
          }}
        />
        <Input
          label={"Description"}
          styled={"filled-input"}
          value={addCompanyData.description}
          setValue={(value) => {
            setAddCompanyData({ ...addCompanyData, description: value });
          }}
        />
        <Input
          label={"Email"}
          styled={"filled-input"}
          value={addCompanyData.email}
          setValue={(value) => {
            setAddCompanyData({ ...addCompanyData, email: value });
          }}
        />
        <div className="d-flex justify-content-end gap-3">
          <Button
            title={"Annuler"}
            styled={"secondary"}
            onClick={handleCloseModal}
          />
          <Button
            title={"Créer une entreprise"}
            styled={"primary"}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddCompanyModal;
