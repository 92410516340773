import { useNavigate, useParams } from "react-router-dom";
import OfferDetailsHeader from "./OfferDetailsHeader";
import { useEffect, useState } from "react";
import { getOfferDetails } from "./services";
import OfferInfoCard from "./OfferInfoCard";
import OfferPricings from "./OfferPricing";
import OfferRules from "./OfferRules";

const OfferDetails = () => {
  const [offerDetails, setOfferDetails] = useState({});
  const [offerUpdated, setOfferUpdated] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getOfferDetails(id)
      .then((response) => {
        setOfferDetails(response.data);
      })
      .catch((e) => console.log(e));
  }, [id, offerUpdated]);
  return (
    <div className="d-flex flex-column w-100">
      <OfferDetailsHeader
        offerDetails={offerDetails}
        setOfferUpdated={setOfferUpdated}
        navigate={navigate}
      />
      <div className="d-flex m-5 gap-5">
        <div className="col-3 d-flex flex-column gap-5">
          <OfferInfoCard offerDetails={offerDetails} />
          <OfferPricings offerDetails={offerDetails} />
        </div>
        <div className="col-8">
          <OfferRules offerDetails={offerDetails} />
        </div>
      </div>
    </div>
  );
};

export default OfferDetails;
