import { useEffect, useState } from "react";
import { formatDate } from "../../utils/utilities";
import Button from "../../components/ui/Button";
import { FiPlus } from "react-icons/fi";
import AddRoleModal from "./AddRoleModal";

const ContractRolesCard = ({
  contractDetails,
  setContractUpdated,
  navigate,
  ...props
}) => {
  const [roles, setRoles] = useState([]);
  const [addRoleModalIsOpen, setAddRoleModalIsOpen] = useState(false);

  useEffect(() => {
    if (!!contractDetails.roles) {
      const Roles = Object.entries(contractDetails.roles).map(
        ([key, value]) => {
          return value.map((el) => {
            return {
              personId: el.personId,
              personName: el.personName,
              date: formatDate(el.creationDateRole),
              email: el.email,
              personType: el.personType,
              roleName: key,
            };
          });
        }
      );
      const FlatRoles = Roles.flatMap((el) => el);
      setRoles(FlatRoles);
    }
  }, [contractDetails]);
  return (
    <div className="info-card-container d-flex flex-column p-3 gap-3">
      <AddRoleModal
        contractId={contractDetails.id}
        setContractUpdated={setContractUpdated}
        addRoleModalIsOpen={addRoleModalIsOpen}
        setAddRoleModalIsOpen={setAddRoleModalIsOpen}
      />
      {!!roles.length ? (
        <>
          <span className="title-element">{`Rôles (${roles.length})`}</span>
          <div className="d-flex flex-column">
            {roles.map((role, index) => (
              <div
                key={index}
                className="d-flex justify-content-between py-4 border-bottom align-items-end"
              >
                <div
                  className="col-3 relation-link-name"
                  onClick={() => {
                    navigate(
                      `${
                        role.personType === "NP" ? "/persons/" : "/companies/"
                      }${role.personId}`
                    );
                    setRoles([]);
                  }}
                >
                  {role.personName}
                </div>
                <div className="d-flex flex-column col-3">
                  <span className="title-element">Type du rôle</span>
                  <span>{role.roleName}</span>
                </div>
                <div className="d-flex flex-column col-3">
                  <span className="title-element">
                    Date de création du rôle
                  </span>
                  <span>{role.date}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div>Aucun rôle</div>
      )}
      <div>
        <Button
          styled="primary"
          title="Ajouter un rôle"
          type="trailing-icon"
          onClick={() => {
            setAddRoleModalIsOpen(true);
          }}
          icon={<FiPlus />}
        />
      </div>
    </div>
  );
};

export default ContractRolesCard;
