import React, { useState } from "react";
import "./properties.scss";

import { tabs } from "./config";
import PropertyList from "./PropertyList";

import {
  createPersonProprety,
  getAllPersonProperties,
} from "../personne/services";
import {
  createCompanyProprety,
  getAllCompanyProperties,
} from "../companies/services";
import {
  createContractProprety,
  getAllContractProperties,
} from "../contrat/services";
import {
  createProductProperty,
  getAllProductProperties,
} from "../products/services";

const Properties = () => {
  const [activeTab, setActiveTab] = useState("Personnes");

  const handleTabs = (key) => {
    setActiveTab(key);
  };
  return (
    <div className="d-flex flex-column gap-5 m-5 w-100">
      <div className="flex-row fs-4">Propriétés</div>
      <div className="d-flex nav-tabs gap-5">
        {tabs.map((el) => (
          <span
            key={el}
            className={`tab-item ${activeTab === el && "active-tab"}`}
            onClick={() => {
              handleTabs(el);
            }}
          >
            {el}
          </span>
        ))}
      </div>
      {activeTab === "Personnes" && (
        <PropertyList
          createProprety={createPersonProprety}
          getAllProperties={getAllPersonProperties}
        />
      )}
      {activeTab === "Entreprises" && (
        <PropertyList
          createProprety={createCompanyProprety}
          getAllProperties={getAllCompanyProperties}
        />
      )}
      {activeTab === "Contrats" && (
        <PropertyList
          createProprety={createContractProprety}
          getAllProperties={getAllContractProperties}
        />
      )}
      {activeTab === "Produits" && (
        <PropertyList
          createProprety={createProductProperty}
          getAllProperties={getAllProductProperties}
        />
      )}
    </div>
  );
};

export default Properties;
