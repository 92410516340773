import { useEffect, useState } from "react";
import Tag from "../../components/ui/Tag";
import Button from "../../components/ui/Button";
import OfferArchive from "./OfferArchive";
import OfferModal from "./OfferModal";

const OfferDetailsHeader = ({
  offerDetails,
  setOfferUpdated,
  navigate,
  ...props
}) => {
  const [offerArchiveIsOpen, setOfferArchiveIsOpen] = useState(false);
  const [createOfferModalIsOpen, setCreateOfferModalIsOpen] = useState(false);
  const [tagType, setTagType] = useState({ type: "", text: "" });
  useEffect(() => {
    switch (offerDetails.status) {
      case "DRAFT":
        setTagType({ type: "warn", text: "Brouillon" });
        break;
      case "ACTIVE":
        setTagType({ type: "success", text: "Activée" });
        break;
      case "ARCHIVED":
        setTagType({ type: "danger", text: "Archivée" });
        break;

      default:
        break;
    }
  }, [offerDetails]);
  return (
    <>
      <OfferModal
        createOfferModalIsOpen={createOfferModalIsOpen}
        setCreateOfferModalIsOpen={setCreateOfferModalIsOpen}
        setOfferAdded={setOfferUpdated}
        offerData={offerDetails}
      />
      <OfferArchive
        offerId={offerDetails.id}
        offerArchiveIsOpen={offerArchiveIsOpen}
        setOfferArchiveIsOpen={setOfferArchiveIsOpen}
        setOfferArchived={setOfferUpdated}
      />
      <div className="details-header d-flex flex-column px-5 py-4 gap-5">
        <div className="d-flex gap-2">
          <span
            className="pathlink-item"
            onClick={() => {
              navigate("/offers");
            }}
          >
            {"Offres >"}
          </span>
          <span>{offerDetails.name}</span>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column gap-2">
            <div>
              <span className="title-element">Référence : </span>
              <span>{offerDetails.id?.slice(0, 8)}</span>
            </div>
            <div className="d-flex gap-4 align-items-center">
              <span className="fs-4">{offerDetails.name}</span>
              <Tag type={tagType.type} tag={tagType.text} />
            </div>
          </div>
          {offerDetails.status !== "ARCHIVED" && (
            <div className="d-flex justify-content-end gap-3 mt-auto">
              <Button
                title={"Archiver"}
                styled={"secondary"}
                onClick={() => {
                  setOfferArchiveIsOpen(true);
                }}
              />

              <Button
                title={"Modifier"}
                styled={"primary"}
                onClick={() => {
                  setCreateOfferModalIsOpen(true);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OfferDetailsHeader;
