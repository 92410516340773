import React, { FC, useState } from 'react';
import Card from '@mui/material/Card';
import Input from "../../components/ui/Input";
import Button from '../../components/ui/Button';
import logo from '../../img/iosis-logo.svg'
import { useNavigate } from 'react-router-dom';
import { resendPassword } from './service';


interface ResetPasswordProps { }

const ResetPassword: FC<ResetPasswordProps> = () => {

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const navigate = useNavigate();

  const onButtonClick = async () => {

    // Set initial error values to empty
    setEmailError('')

    // Check if the user has entered both fields correctly
    if ('' === email) {
      setEmailError('Please enter your email')
      return
    }

    // E-mail as username
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,6}$/.test(email)) {
      setEmailError('Please enter a valid email')
      return
    }


    const payload = {
      action: "UPDATE_PASSWORD"
      //"actions":["UPDATE_PASSWORD"]
    }

    const user_id = "FIXME";

    resendPassword(user_id, payload)
      .then((response: any) => {
        console.log('Keycloak resendPassword response', JSON.stringify(response))

        if (response.status === 200) {
          navigate('/');
          //props.showError(null)
        }
        else {
          setEmailError(response.message)
          // TODO props.showError("Username does not exists");
        }

      })
      .catch((error: any) => {
        console.error(error);
      });

  }


  return (
    <div data-testid="ResetPassword" className="container">
      <div className='header-container my-5'>
        <div className='d-inline-flex align-items-center gap-4'>
          <img className='align-self-center' src={logo} alt="logo"></img>
          <span className='title'> iosis </span>
        </div>
      </div>
      <Card className="col-12">
        <div className="{'titleContainer'} mx-5 mt-3">
          <h3>Réinitialiser mot de passe</h3>
        </div>
        <br />
        <div className="{'inputContainer'} mx-5">
          <Input
            style={{ width: "100%" }}
            value={email}
            label={"E-mail"}
            placeholder="Enter your email here"
            setValue={(value: React.SetStateAction<string>) => {
              setEmail(value)
            }}
            styled={"filled-input"}
            errorMsg={emailError}
          />
        </div>
        <br />
        <div className="{'buttonContainer'} mx-5 mb-3">
          <Button styled={'primary'} onClick={onButtonClick} title={'Envoyer'} />
        </div>
      </Card>
    </div>
  )
}

export default ResetPassword;
