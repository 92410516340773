import React, { useEffect, useState } from "react";
import Button from "../../components/ui/Button";
import { FiPlus } from "react-icons/fi";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { getAllRoles } from "../contrat/services";
import AddRoleModal from "./AddRoleModal";
import { formatDate } from "../../utils/utilities";

const Roles = () => {
  const [rolesList, setRolesList] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 20 });
  const [roleModal, setRoleModal] = useState(false);
  const [roleAdded, setRoleAdded] = useState(false);

  const columns = [
    {
      field: "id",
      headerName: "Référence",
      valueGetter: (value) => `${value.slice(0, 8)}`,
      flex: 1,
    },
    {
      field: "label",
      headerName: "Label",
      flex: 1,
    },
    {
      field: "code",
      headerName: "Code",
      flex: 1,
    },
    {
      field: "creationDate",
      headerName: "Créé le",
      valueGetter: (value) => {
        return formatDate(value);
      },
      flex: 1,
    },
  ];

  useEffect(() => {
    getAllRoles()
      .then((response) => {
        setRolesList(response.data);
      })
      .catch((e) => console.log(e));
  }, [roleAdded]);
  return (
    <div className="d-flex flex-column gap-5 m-5 w-100">
      <AddRoleModal
        roleModal={roleModal}
        setRoleModal={setRoleModal}
        setRoleAdded={setRoleAdded}
      />
      <div className="flex-row fs-4">Rôles</div>
      {rolesList.length ? (
        <>
          <div className="d-flex flex-row-reverse">
            <Button
              styled="primary"
              title="Créer un rôle"
              type="trailing-icon"
              onClick={() => {
                setRoleModal(true);
              }}
              icon={<FiPlus />}
            />
          </div>

          <div className="flex-row">
            <DataGrid
              rows={rolesList}
              columns={columns}
              paginationModel={pagination}
              onPaginationModelChange={setPagination}
              getRowHeight={() => "auto"}
              sx={{
                [`& .${gridClasses.cell}`]: {
                  py: 1,
                },
              }}
              disableColumnMenu
              disableColumnResize
              disableColumnSorting
              disableRowSelectionOnClick
              onRowClick={({ id }) => {}}
            ></DataGrid>
          </div>
        </>
      ) : (
        <div className=" d-flex flex-column align-items-center gap-4 m-auto">
          <span>Il n’y a actuellement aucun rôle dans la liste</span>
          <span>
            <Button
              styled="primary"
              title="Créer un rôle"
              type="trailing-icon"
              onClick={() => {
                setRoleModal(true);
              }}
              icon={<FiPlus />}
            />
          </span>
        </div>
      )}
    </div>
  );
};

export default Roles;
