import React, { useEffect, useState } from "react";

const Card = ({ title, style, columns, data, icon, onClickIcon, ...props }) => {
  return (
    <div
      className="info-card-container d-flex flex-column p-3 gap-3"
      style={{ width: style?.width }}
    >
      <div className="d-flex justify-content-between">
        <span className="title-element">{title}</span>
        {!!icon && !!onClickIcon && <span onClick={onClickIcon}>{icon}</span>}
      </div>

      <div className="d-flex flex-wrap">
        {data?.map((el, i) => (
          <div key={i} className={`d-flex flex-column col-${12 / columns} p-2`}>
            <span className="title-element">{el.label}</span>
            <div className="d-flex gap-2">
              <span>
                {typeof el.value === "boolean"
                  ? !!el.value
                    ? "Oui"
                    : "Non"
                  : el.value}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Card;
