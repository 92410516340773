const Tag = ({ type, tag, ...props }) => {
  const Styles = {
    success: {
      backgroundColor: "#009C6A",
      color: "#ffffff",
      borderRadius: "5px",
      padding: "5px 20px",
    },
    danger: {
      backgroundColor: "#eb4141",
      color: "#ffffff",
      borderRadius: "5px",
      padding: "5px 20px",
    },
    warn: {
      backgroundColor: "#ffcc3b",
      color: "#ffffff",
      borderRadius: "5px",
      padding: "5px 20px",
    },
  };
  return <span style={Styles[type]}>{tag}</span>;
};

export default Tag;
