import { useEffect, useState } from "react";
import Card from "../../components/ui/Card";

const OfferRules = ({ offerDetails, ...props }) => {
  const formattedRules = offerDetails?.rules?.map((el) => {
    const obj = JSON.parse(el.value);
    if (el.propertyType === "Number" || el.propertyType === "Date") {
      return { ...el, value: `Entre ${obj.minValue} et ${obj.maxValue}` };
    }
    if (el.propertyType === "String" || el.propertyType === "Boolean") {
      return { ...el, value: obj.value };
    }
    if (el.propertyType === "List") {
      return { ...el, value: obj.selectedValues.join(" - ") };
    }
  });
  return <Card data={formattedRules} columns={3} title={"Configuration"} />;
};

export default OfferRules;
