import { useEffect, useState } from "react";
import Button from "../../components/ui/Button";
import { FiPlus } from "react-icons/fi";
import AddPersonLinkModal from "./AddPersonLinkModal";
import { formatDate } from "../../utils/utilities";
import { addRelationToPerson } from "../personne/services";
import { addRelationToCompany } from "../companies/services";
import { getAllPersonByIdNaturalPersonLinks  } from "./services";

const NaturalPersonRelationsCard = ({
  personDetails,
  personType,
  navigate,
  setPersonUpdated,
  ...props
}) => {
  const [naturalPersonLinks, setNaturalPersonLinks] = useState([]);
  const [addRelationModalIsOpen, setAddRelationModalIsOpen] = useState(false);

  useEffect(() => {
    if (!!personDetails.id) {
      getAllPersonByIdNaturalPersonLinks(personDetails.id)
      .then((res) => {
        console.log(res)
        setNaturalPersonLinks(res.data);
      })
      .catch((e) => console.error(e));
    }
  }, [personDetails?.id]);

  return (
    <div className="info-card-container d-flex flex-column p-3 gap-3">
      <AddPersonLinkModal
        personId={personDetails.id}
        personType={"NP"}
        addRelation={personType === "NP" ? addRelationToPerson : addRelationToCompany}
        setPersonUpdated={setPersonUpdated}
        addRelationModalIsOpen={addRelationModalIsOpen}
        setAddRelationModalIsOpen={setAddRelationModalIsOpen}
      />
      {!!naturalPersonLinks.length ? (
        <>
          <span className="title-element">{`Relations avec les personnes (${naturalPersonLinks.length})`}</span>
          <div className="d-flex flex-column">
            {naturalPersonLinks.map((naturalPersonLink) => (
              <div className="d-flex justify-content-between py-4 border-bottom align-items-end">
                <div
                  className="col-3 relation-link-name"
                  onClick={() => {
                    navigate(`/persons/${naturalPersonLink.naturalPerson?.id}`);
                    setNaturalPersonLinks([]);
                  }}
                >
                  {naturalPersonLink.naturalPerson?.firstName} {naturalPersonLink.naturalPerson?.lastName}
                </div>
                <div className="d-flex flex-column col-3">
                  <span className="title-element">Type de relation</span>
                  <span>{naturalPersonLink.relation?.code}</span>
                </div>
                <div className="d-flex flex-column col-3">
                  <span className="title-element">
                    Date de création
                  </span>
                  <span>{formatDate(naturalPersonLink.creationDate)}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div>Aucune relation avec les personnes</div>
      )}
      <div>
        <Button
          styled="primary"
          title="Ajouter une relation"
          type="trailing-icon"
          onClick={() => {
            setAddRelationModalIsOpen(true);
          }}
          icon={<FiPlus />}
        />
      </div>
    </div>
  );
};

export default NaturalPersonRelationsCard;
