import { AxiosHeaders } from "axios";
import { config, keycloakAxios } from "../../config/config";


let headers = new AxiosHeaders();
headers.setContentType('application/x-www-form-urlencoded');

const login = (payload: any) => {
    return keycloakAxios.post(config.KEYCLOAK_BASE_URL + '/realms/master/protocol/openid-connect/token', payload, {
        headers: headers,
        withCredentials: true
    })
}

export { login };