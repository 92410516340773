import React, { FC, useState } from 'react';
import Card from '@mui/material/Card';
import Input from "../../components/ui/Input";
import Button from '../../components/ui/Button';
import logo from '../../img/iosis-logo.svg'
import { useNavigate } from 'react-router-dom';
import { login } from './service';


interface LoginProps { }

const Login: FC<LoginProps> = () => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')

  const navigate = useNavigate();

  const onButtonClick = async () => {

    // Set initial error values to empty
    setEmailError('')
    setPasswordError('')

    // Check if the user has entered both fields correctly
    if ('' === email) {
      setEmailError('Please enter your email')
      return
    }

    // E-mail as username
    if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,6}$/.test(email)) {
      setEmailError('Please enter a valid email')
      return
    }

    if ('' === password) {
      setPasswordError('Please enter a password')
      return
    }

    if (password.length < 8) {
      setPasswordError('The password must be 8 characters or longer')
      return
    }

    if (process.env.REACT_APP_AUTH === "keycloak-iosis") {

      const payload = {
        client_id: "iosis",
        grant_type: "password",
        username: email,
        email: email,
        password: password, // FIXME crypt
        /*
        "enabled": true,
        "credentials": [{
          "type": "password",
          "value": password,
          "temporary": false
        }],
        */
      }

      login(payload)
        .then((response: { status: number; data: { access_token: string; refresh_token: string; expires_in: string; refresh_expires_in: string; }; }) => {
          console.log('Keycloak login response', JSON.stringify(response))

          if (response.status === 200) {
            localStorage.setItem('access_token', response.data.access_token);
            localStorage.setItem('refresh_token', response.data.refresh_token);

            localStorage.setItem('expires_in', response.data.expires_in);
            localStorage.setItem('refresh_expires_in', response.data.refresh_expires_in);

            navigate('/');
            window.dispatchEvent(new Event("login"));
            //props.showError(null)
          }
          else {
            setEmailError('Invalid user credentials')
            setPasswordError('Invalid user credentials')
            // TODO props.showError("Username does not exists");
          }

        })
        .catch((error: any) => {
          console.log(error);
        });

    } else if (process.env.REACT_APP_AUTH === "iosis") {


    }
    
  }


  return (
    <div data-testid="Login" className="container">
      <div className='header-container my-5'>
        <div className='d-inline-flex align-items-center gap-4'>
          <img className='align-self-center' src={logo} alt="logo"></img>
          <span className='title'> iosis </span>
        </div>
      </div>
      <Card className="col-12">
        <div className="{'titleContainer'} mx-5 mt-3">
          <h3>Se connecter</h3>
        </div>
        <br />
        <div className="{'inputContainer'} mx-5">
          <Input
            style={{ width: "100%" }}
            value={email}
            label={"E-mail"}
            placeholder="Enter your email here"
            setValue={(value: React.SetStateAction<string>) => {
              setEmail(value)
            }}
            styled={"filled-input"}
            errorMsg={emailError}
          />
        </div>
        <br />
        <div className="{'inputContainer'} mx-5">
          <Input
            type={"password"}
            style={{ width: "100%" }}
            value={password}
            label={"Password"}
            placeholder="Enter your password here"
            setValue={(value: React.SetStateAction<string>) => {
              setPassword(value)
            }}
            //onChange={(ev: { target: { value: React.SetStateAction<string>; }; }) => setPassword(ev.target.value)}
            styled={"filled-input"}
            errorMsg={passwordError}
          />
        </div>
        <div className="resetPasswordContainer mx-5 mt-2"
          onClick={() => {
            navigate(`/password/reset`)
          }}>
          Mot de passe oublié?
        </div>
        <br />
        <div className="{'buttonContainer'} mx-5 mb-3">
          <Button styled={'primary'} onClick={onButtonClick} title={'Login'} />
        </div>
      </Card>
    </div>
  )
}

export default Login;
