import React, { useEffect, useState } from "react";
import Button from "../../components/ui/Button";
import { FiPlus } from "react-icons/fi";
import { MdDelete, MdModeEdit } from "react-icons/md";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { getAllOffers } from "./services";
import OfferModal from "./OfferModal";
import OfferArchive from "./OfferArchive";

const Offers = () => {
  const [offerList, setOfferList] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 20 });
  const [createOfferModalIsOpen, setCreateOfferModalIsOpen] = useState(false);
  const [offerArchiveIsOpen, setOfferArchiveIsOpen] = useState(false);
  const [offerAdded, setOfferAdded] = useState(false);
  const [offerArchived, setOfferArchived] = useState(false);
  const [offerData, setOfferData] = useState({});
  const [offerId, setOfferId] = useState(null);

  const navigate = useNavigate();

  const ActionCell = (params) => {
    return (
      <div>
        <MdModeEdit
          className="mx-2"
          style={{ cursor: "pointer" }}
          size={24}
          onClick={(event) => {
            event.stopPropagation();
            setCreateOfferModalIsOpen(true);
            setOfferData(params.row);
          }}
          visibility={params.row?.status === "ARCHIVED" ? "hidden" : "visible"}
        />
        <MdDelete
          className="mx-2"
          style={{ cursor: "pointer" }}
          size={24}
          onClick={(event) => {
            event.stopPropagation();
            setOfferArchiveIsOpen(true);
            setOfferId(params.row?.id);
          }}
          visibility={params.row?.status === "ARCHIVED" ? "hidden" : "visible"}
        />
      </div>
    );
  };
  const columns = [
    {
      field: "id",
      headerName: "Référence",
      valueGetter: (value) => `${value.slice(0, 8)}`,
      flex: 1,
    },

    {
      field: "name",
      headerName: "Nom de l'offre",
      flex: 1,
    },
    {
      field: "startDate",
      headerName: "Date de début",
      flex: 1,
    },
    {
      field: "endDate",
      headerName: "Date de fin",
      flex: 1,
    },

    {
      field: "status",
      headerName: "Statut",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: ActionCell,
      flex: 1,
    },
  ];

  useEffect(() => {
    getAllOffers()
      .then((res) => {
        setOfferList(res.data);
      })
      .catch((e) => console.log(e));
  }, [offerAdded, offerArchived]);
  return (
    <>
      <OfferModal
        createOfferModalIsOpen={createOfferModalIsOpen}
        setCreateOfferModalIsOpen={setCreateOfferModalIsOpen}
        setOfferAdded={setOfferAdded}
        offerData={offerData}
      />
      <OfferArchive
        offerId={offerId}
        offerArchiveIsOpen={offerArchiveIsOpen}
        setOfferArchiveIsOpen={setOfferArchiveIsOpen}
        setOfferArchived={setOfferArchived}
      />
      <div className="d-flex flex-column gap-5 m-5 w-100">
        <div className="flex-row fs-4">Offres</div>
        {offerList.length ? (
          <>
            <div className="d-flex flex-row-reverse">
              <Button
                styled="primary"
                title="Créer une offre"
                type="trailing-icon"
                onClick={() => {
                  setCreateOfferModalIsOpen(true);
                }}
                icon={<FiPlus />}
              />
            </div>
            <div className="flex-row">
              <DataGrid
                rows={offerList}
                columns={columns}
                paginationModel={pagination}
                onPaginationModelChange={setPagination}
                disableColumnMenu
                disableColumnResize
                disableColumnSorting
                disableRowSelectionOnClick
                onRowClick={({ id }) => {
                  navigate(`/offers/${id}`);
                }}
              ></DataGrid>
            </div>
          </>
        ) : (
          <div className=" d-flex flex-column align-items-center gap-4 m-auto">
            <span>Il n’y a actuellement aucune offre dans la liste</span>
            <span>
              <Button
                styled="primary"
                title="Créer une offre"
                type="trailing-icon"
                onClick={() => {
                  setCreateOfferModalIsOpen(true);
                }}
                icon={<FiPlus />}
              />
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default Offers;
