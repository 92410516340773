import React, { useEffect, useState } from "react";
import ProductDetailsHeader from "./ProductDetailsHeader";
import ProductInfoCard from "./ProductInfoCard";
import ProductPropertiesCard from "./ProductPropertiesCard.jsx";
import ProductOffersCard from "./ProductOffersCard";
import { useNavigate, useParams } from "react-router-dom";
import { getProductDetails } from "./services";

const ProductDetails = () => {
  const [productDetails, setProductDetails] = useState({});
  const [productUpdated, setProductUpdated] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    getProductDetails(id)
      .then((response) => {
        setProductDetails(response.data);
      })
      .catch((e) => console.log(e));
  }, [id, productUpdated]);
  return (
    <div className="d-flex flex-column w-100">
      <ProductDetailsHeader
        productDetails={productDetails}
        setProductUpdated={setProductUpdated}
        navigate={navigate}
      />
      <div className="d-flex m-5 gap-5">
        <div className="col-3">
          <ProductInfoCard
            productDetails={productDetails}
            setProductUpdated={setProductUpdated}
          />
        </div>
        <div className="col-8 d-flex flex-column gap-5">
          <ProductPropertiesCard
            productDetails={productDetails}
            setProductUpdated={setProductUpdated}
          />

          <ProductOffersCard
            productDetails={productDetails}
            setProductUpdated={setProductUpdated}
            navigate={navigate}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
