import React, { useEffect, useState } from "react";
import { getAllContracts } from "./services";
import Button from "../../components/ui/Button";
import { FiPlus } from "react-icons/fi";
import { DataGrid } from "@mui/x-data-grid";
import AddContractModal from "./AddContractModal";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/utilities";

const Contracts = () => {
  const [contratList, setContratsList] = useState([]);
  const [pagination, setPagination] = useState({ page: 0, pageSize: 20 });
  const [addContractModalIsOpen, setAddContractModalIsOpen] = useState(false);
  const [contractAdded, setContractAdded] = useState(false);

  const navigate = useNavigate();

  const columns = [
    {
      field: "id",
      headerName: "Référence",
      valueGetter: (value) => `${value.slice(0, 8)}`,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
    },
    {
      field: "name",
      headerName: "Nom du contrat",
      flex: 1,
    },
    {
      field: "country",
      headerName: "Pays",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Statut",
      flex: 1,
    },
    {
      field: "creationDate",
      headerName: "Créé le",
      valueGetter: (value) => {
        return formatDate(value);
      },
      flex: 1,
    },
  ];

  useEffect(() => {
    getAllContracts()
      .then((res) => {
        setContratsList(res.data);
      })
      .catch((e) => console.log(e));
  }, [contractAdded]);
  return (
    <>
      <AddContractModal
        addContractModalIsOpen={addContractModalIsOpen}
        setAddContractModalIsOpen={setAddContractModalIsOpen}
        setContractAdded={setContractAdded}
      />
      <div className="d-flex flex-column gap-5 m-5 w-100">
        <div className="flex-row fs-4">Contrats</div>
        {contratList.length ? (
          <>
            <div className="d-flex flex-row-reverse">
              <Button
                styled="primary"
                title="Créer un contrat"
                type="trailing-icon"
                onClick={() => {
                  setAddContractModalIsOpen(true);
                }}
                icon={<FiPlus />}
              />
            </div>
            <div className="flex-row">
              <DataGrid
                rows={contratList}
                columns={columns}
                paginationModel={pagination}
                onPaginationModelChange={setPagination}
                disableColumnMenu
                disableColumnResize
                disableColumnSorting
                disableRowSelectionOnClick
                onRowClick={({ id }) => {
                  navigate(`/contracts/${id}`);
                }}
              ></DataGrid>
            </div>
          </>
        ) : (
          <div className=" d-flex flex-column align-items-center gap-4 m-auto">
            <span>Il n’y a actuellement aucun contrat dans la liste</span>
            <span>
              <Button
                styled="primary"
                title="Créer un contrat"
                type="trailing-icon"
                onClick={() => {
                  setAddContractModalIsOpen(true);
                }}
                icon={<FiPlus />}
              />
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default Contracts;
