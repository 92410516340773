import { FC } from 'react'
import logo from '../../img/iosis-logo.svg'
import profilepic from '../../img/profile-pic.png'
import GetKeycloakInstance from "../../utils/keycloak";
import './header.scss'
import Button from '../ui/Button';
import { FaSignOutAlt } from 'react-icons/fa';
import { FaGear } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { decodedToken } from '../../utils/token';
import { iosisLogout, keycloakLogout } from './service';

const Header: FC = () => {

    const navigate = useNavigate();
    const tokenInfo: any = decodedToken();

    const isAdmin: boolean = tokenInfo?.realm_access?.roles?.includes('admin');

    const handelLogout = async () => {

        if (process.env.REACT_APP_AUTH === "keycloak") {

            const keycloak = (await GetKeycloakInstance());

            console.log('Logout Keycloak', JSON.stringify(keycloak))

            return keycloak?.logout()//{ redirectUri: config.KEYCLOAK_BASE_URL });

        } else if (process.env.REACT_APP_AUTH === "keycloak-iosis") {

            keycloakLogout();

        } else if (process.env.REACT_APP_AUTH === "iosis") {

            iosisLogout();

        }

    };


    return (
        <div className='header-container'>
            <div className='d-inline-flex align-items-center gap-4'>
                <img className='align-self-center' src={logo} alt="logo"></img>
                <span className='title'> iosis </span>
            </div>
            <div className='d-inline-flex align-items-center gap-3'>
                <span onClick={() => {
                        navigate(`/user-info`);
                    }}>{tokenInfo ? tokenInfo.email : ''}</span>
                <span>
                    <img className='rounded-circle' src={profilepic} height={'40px'} alt="profile"></img>
                </span>
                {isAdmin && <Button
                    styled="primary-button"
                    type="leading-icon"
                    onClick={() => {
                        navigate(`/register`);
                    }}
                    icon={< FaGear />}
                />}
                <Button
                    styled="primary-button"
                    type="leading-icon"
                    onClick={() => {
                        handelLogout();
                    }}
                    icon={< FaSignOutAlt />}
                />
            </div>
        </div>
    )
}

export default Header
