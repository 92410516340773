import React, { useState } from "react";
import "./index.scss";
import { FiEye, FiEyeOff } from "react-icons/fi";
function Input({ ...props }) {
  
  const [showPassword, setShowPassword] = useState(false);
  
  return (
    <div
      className={`${props.styled === "standard-input" && "w-100"}`}
      style={props?.style}
    >
      {props?.label && <label>{props.label}</label>}
      {props.type === "textArea" ? (
        <textarea
          className={props.styled}
          placeholder={props.placeholder}
          disabled={props.disabled}
          value={props.value}
          onChange={(e) => {
            props.setValue(e.target.value);
          }}
        ></textarea>
      ) : (
        <div className="d-flex align-items-center input-container">
          <input
            className={props.styled}
            type={(props?.type === "password" && showPassword && "text") || props?.type}
            placeholder={props.placeholder}
            disabled={props.disabled}
            value={props.value}
            onChange={(e) => {
              props.setValue(
                props.type === "number"
                  ? Number(e.target.value)
                  : e.target.value
              );
            }}
          ></input>
          {props?.type === "password" && (
            <>
              {!showPassword ? (
                <FiEye
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              ) : (
                <FiEyeOff
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              )}
            </>
          )}
          <span>{props.icon && props.icon}</span>
        </div>
      )}

      {props.errorMsg && (
        <span style={{ color: "red", fontSize: "12px" }}>{props.errorMsg}</span>
      )}
    </div>
  );
}

export default Input;
