import { AxiosHeaders } from "axios";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { config, keycloakAxios } from "../config/config";



export const decodedToken = () => {

    const accessToken = localStorage.getItem("access_token");

    let decodedToken = null;
    if (accessToken) {
        decodedToken = jwtDecode<JwtPayload>(accessToken);
    }

    console.log('decodedToken', JSON.stringify(decodedToken))

    return decodedToken;
}


export const interceptToken = () => {

    config.axios.interceptors.request.use(
        (config: { headers: { Authorization: string } }) => {
            const accessToken = localStorage.getItem("access_token");

            if (config.headers) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }

            return config;
        }
    );

    keycloakAxios.interceptors.request.use(
        (config) => {
            const accessToken = localStorage.getItem("access_token");

            if (config.headers) {
                config.headers.Authorization = `Bearer ${accessToken}`;
            }

            return config;
        }
    );

}


export const interceptResponse = () => {

    config.axios.interceptors.response.use((response: any) => {
        if (response?.status === 401) {
            window.dispatchEvent(new Event("logout"));
        }
        return response;
    }, (error: any) => {
        console.error(error);
        if (error.code === "ERR_NETWORK")
            window.dispatchEvent(new Event("logout"));
        
        return error;
    }
    );

    keycloakAxios.interceptors.response.use((response: any) => {
        if (response?.status === 401) {
            window.dispatchEvent(new Event("logout"));
        }
        return response;
    }, (error: any) => {
        console.error(error);
        if (error.code === "ERR_NETWORK")
            window.dispatchEvent(new Event("logout"));

        return error;
    }
    );

}


export const refreshToken = () => {

    const payload = {
        client_id: "iosis",
        grant_type: "refresh_token",
        refresh_token: localStorage.getItem('refresh_token')
    }

    let headers = new AxiosHeaders();
    headers.setContentType('application/x-www-form-urlencoded');

    keycloakAxios.post(config.KEYCLOAK_BASE_URL + '/realms/master/protocol/openid-connect/token',
        payload,
        {
            headers: headers,
            withCredentials: true
        })
        .then((response: { status: number; data: { access_token: string; refresh_token: string; expires_in: string; refresh_expires_in: string; }; }) => {

            console.log('Keycloak refresh token response', JSON.stringify(response))

            if (response.status === 200) {

                localStorage.setItem('access_token', response.data.access_token);
                localStorage.setItem('refresh_token', response.data.refresh_token);

                localStorage.setItem('expires_in', response.data.expires_in);
                localStorage.setItem('refresh_expires_in', response.data.refresh_expires_in);

            } else {

                window.dispatchEvent(new Event("logout"));

            }
        })
        .catch((error: any) => {
            window.dispatchEvent(new Event("logout"));
            console.log(error);
        });

};

