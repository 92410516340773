import { useEffect, useState } from "react";
import Button from "../../components/ui/Button";
import { FiPlus } from "react-icons/fi";
import { getAllPricingByOffer, getOffersByProduct } from "../offers/services";
import OfferModal from "../offers/OfferModal";

const PaymentTypes = {
  ONESHOT: "Paiement comptant",
  MONTH: "Paiement mensuel",
  YEAR: "Paiement annuel",
};

const ProductOffersCard = ({
  productDetails,
  setProductUpdated,
  navigate,
  ...props
}) => {
  const [offers, setOffers] = useState([]);
  const [createOfferModalIsOpen, setCreateOfferModalIsOpen] = useState(false);
  const [offerData, setOfferData] = useState({});
  const [offerAdded, setOfferAdded] = useState(false);

  var Offers = [];

  //FIXME in BE
  //Create Endpoint in the Orchestrator
  useEffect(() => {
    if (!!productDetails?.id) {
      setOfferData({
        productId: {
          id: productDetails.id,
          value: productDetails.id,
          label: productDetails.name,
        },
      });
      Offers = [];
      getOffersByProduct(productDetails.id)
        .then((response) => {
          response.data.forEach(async (offer) => {
            await getAllPricingByOffer(offer.id)
              .then(({ data }) => {
                Offers.push({ ...offer, prices: data });
                setOffers(Offers);
              })
              .catch((e) => console.log(e));
          });
        })
        .catch((e) => console.log(e));
    }
  }, [productDetails, offerAdded]);
  return (
    <>
      <OfferModal
        createOfferModalIsOpen={createOfferModalIsOpen}
        setCreateOfferModalIsOpen={setCreateOfferModalIsOpen}
        setOfferAdded={setOfferAdded}
        offerData={offerData}
      />
      <div className="info-card-container d-flex flex-column p-3 gap-3">
        <span className="title-element">Offres</span>
        {!!offers.length ? (
          offers.map((el, index) => (
            <div
              key={index}
              className="d-flex py-4 border-bottom align-items-end"
            >
              <div
                className="col-3 relation-link-name"
                onClick={() => {
                  navigate(`${"/offers/"}${el.id}`);
                }}
              >
                {el.name}
              </div>

              {el.prices.map((price) => (
                <div className="d-flex flex-column col-3">
                  <span className="title-element">
                    {PaymentTypes[price.periodType]}
                  </span>
                  <span>{price.price + " " + price.currency}</span>
                </div>
              ))}
            </div>
          ))
        ) : (
          <div>Aucune offre</div>
        )}
        <div>
          <Button
            styled="primary"
            title="Créer une offre"
            type="trailing-icon"
            onClick={() => {
              setCreateOfferModalIsOpen(true);
            }}
            icon={<FiPlus />}
          />
        </div>
      </div>
    </>
  );
};

export default ProductOffersCard;
