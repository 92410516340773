import React, { useEffect, useState } from "react";
import Button from "../../components/ui/Button";
import { FiX } from "react-icons/fi";
import Modal from "react-modal";
import Input from "../../components/ui/Input";
import {
  createProduct,
  getAllProductProperties,
  updateProduct,
} from "./services";
import DropdownMultiple from "../../components/ui/DropdownMuliple";

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    padding: "0px",
    minHeight: "60%",
    overflow: "visible",
  },
};

const ProductModal = ({
  productData,
  setProductData,
  productModalIsOpen,
  setProductModalIsOpen,
  setProductAdded,
  ...props
}) => {
  const [allpropertyList, setAllpropertyList] = useState([]);

  useEffect(() => {
    getAllProductProperties()
      .then((response) => {
        setAllpropertyList(response.data);
      })
      .catch((e) => console.log(e));
  }, []);

  const handleCloseModal = () => {
    setProductModalIsOpen(false);
    setProductData({});
  };
  const handleSubmit = () => {
    productData?.id
      ? updateProduct(productData)
          .then((resp) => {
            handleCloseModal();
            setProductAdded((prevState) => {
              return !prevState;
            });
          })
          .catch((e) => console.log(e))
      : createProduct(productData)
          .then((response) => {
            handleCloseModal();
            setProductAdded((prevState) => {
              return !prevState;
            });
          })
          .catch((e) => console.log(e));
  };
  return (
    <Modal isOpen={productModalIsOpen} style={ModalStyle}>
      <div className="modal-header">
        <h5>
          {productData?.id ? "Mettre à jour le produit" : "Créer un produit"}
        </h5>
        <FiX
          size={24}
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleCloseModal();
          }}
        />
      </div>
      <div className="d-flex flex-column p-4 gap-3">
        <div className="d-flex flex-column gap-4">
          <span>Informations</span>
          <Input
            label={"Nom du produit"}
            styled={"filled-input"}
            value={productData.name}
            setValue={(value) => {
              setProductData({ ...productData, name: value });
            }}
          />
          <Input
            label={"Description"}
            type={"textArea"}
            styled={"filled-input"}
            value={productData.description}
            setValue={(value) => {
              setProductData({ ...productData, description: value });
            }}
          />
        </div>
        <hr></hr>
        <div className="d-flex flex-column gap-4">
          <span>Propriétés</span>
          <div className="d-flex flex-column">
            <label style={{ fontWeight: "bold" }}>Liste des propriétés</label>
            <span style={{ marginBottom: "5px" }}>
              Veuillez sélectionner les propriétés que vous souhaitez ajouter au
              produit
            </span>

            <DropdownMultiple
              dataList={allpropertyList}
              checkedItems={productData?.properties}
              placeholder="Sélectionner un élément"
              searchEnabled={true}
              onSelectItems={(selectedProperties) => {
                setProductData({
                  ...productData,
                  properties: selectedProperties,
                });
              }}
            />
          </div>
          {/* {product && product.properties?.length && (
            <div className="d-flex flex-column gap-2">
              {product.properties.map((el) => (
                <span key={el.code}>{el.label}</span>
              ))}
            </div>
          )} */}
        </div>
        <div className="d-flex justify-content-end gap-3">
          <Button
            title={"Annuler"}
            styled={"secondary"}
            onClick={() => handleCloseModal()}
          />
          <Button
            title={
              productData?.id ? "Mettre à jour le produit" : "Créer un produit"
            }
            styled={"primary"}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ProductModal;
