import React, { useState, useEffect, useLayoutEffect } from "react";
import "./index.scss";
function CustomSwitch({ onToggleState, ...props }) {
  const [state, setState] = useState(props.state);
  /* useEffect(() => {
    if (props?.state !== state)
      setState(props?.state)
  }, [state]) */

  useEffect(() => {
    setState(props?.state);
  }, [props.state]);

  return (
    <div
      className={`switch ${state ? "switchOn" : "switchOff"}`}
      onClick={async () => {
        await onToggleState();
      }}
    >
      <div className="switchbtn"></div>
    </div>
  );
}

export default CustomSwitch;
