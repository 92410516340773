import React, { useState } from "react";
import Button from "../../components/ui/Button";
import { FiX } from "react-icons/fi";
import Modal from "react-modal";
import Input from "../../components/ui/Input";
import { createContract } from "./services";
import Dropdown from "../../components/ui/Dropdown";

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    padding: "0px",
  },
};

const AddContractModal = ({
  addContractModalIsOpen,
  setAddContractModalIsOpen,
  setContractAdded,
  ...props
}) => {
  const [addContractData, setAddContractData] = useState({});

  const handleCloseModal = () => {
    setAddContractModalIsOpen(false);
    setAddContractData({});
  };
  const handleSubmit = () => {
    createContract(addContractData)
      .then((response) => {
        handleCloseModal();
        setContractAdded((prevState) => {
          return !prevState;
        });
      })
      .catch((e) => console.log(e));
  };
  return (
    <Modal isOpen={addContractModalIsOpen} style={ModalStyle}>
      <div className="modal-header">
        <h5>Créer un contrat</h5>
        <FiX
          size={24}
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleCloseModal();
          }}
        />
      </div>
      <div className="d-flex flex-column p-4 gap-4">
        <Input
          label={"Nom du contrat"}
          styled={"filled-input"}
          value={addContractData.name}
          setValue={(value) => {
            setAddContractData({ ...addContractData, name: value });
          }}
        />
        <Input
          label={"Type"}
          styled={"filled-input"}
          value={addContractData.type}
          setValue={(value) => {
            setAddContractData({ ...addContractData, type: value });
          }}
        />
        <Input
          label={"Pays"}
          styled={"filled-input"}
          value={addContractData.country}
          setValue={(value) => {
            setAddContractData({ ...addContractData, country: value });
          }}
        />
        <Input
          label={"Date de début du contrat"}
          styled={"filled-input"}
          value={addContractData.startDate}
          setValue={(value) => {
            setAddContractData({ ...addContractData, startDate: value });
          }}
        />
        <Input
          label={"Date de fin du contrat"}
          styled={"filled-input"}
          value={addContractData.endDate}
          setValue={(value) => {
            setAddContractData({ ...addContractData, endDate: value });
          }}
        />
        <Dropdown
          label={"Statut"}
          value={addContractData.status}
          options={[
            { id: -1, value: null, label: "Choisir le statut" },
            { id: 1, value: "DRAFT", label: "Broullion" },
            { id: 2, value: "PENDING", label: "En attente" },
            { id: 3, value: "APPROVED", label: "Approuvé" },
            { id: 4, value: "VALID", label: "Valide" },
            { id: 5, value: "ACTIVE", label: "Actif" },
          ]}
          setValue={(value) => {
            setAddContractData({ ...addContractData, status: value });
          }}
        />
        <div className="d-flex justify-content-end gap-3">
          <Button
            title={"Annuler"}
            styled={"secondary"}
            onClick={() => handleCloseModal()}
          />
          <Button
            title={"Créer un contrat"}
            styled={"primary"}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddContractModal;
