import { config } from "../../../config/config";

const getAllQuotes = () => {
  return config.axios.get(config.QUOTE_QUERIES_BASE_URL + "/quote");
};

const getQuoteById = (id) => {
  return config.axios.get(config.QUOTE_QUERIES_BASE_URL + "/quote/" + id);
};

const createQuote = () => {
  return config.axios.post(config.QUOTE_COMMANDS_BASE_URL + "/quote");
};

const updateQuote = (quoteId, data) => {
  return config.axios.put(
    config.QUOTE_COMMANDS_BASE_URL + "/quote/" + quoteId,
    data
  );
};

export { getAllQuotes, getQuoteById, createQuote, updateQuote };
