import { AxiosHeaders } from "axios";
import { config, keycloakAxios } from "../../config/config";


let headers = new AxiosHeaders();

headers.setContentType('application/json');

const resendPassword = (user_id: string, resend_password_payload: any) => {
    const endpoint_uri = config.KEYCLOAK_BASE_URL + '/admin/realms/master/users/' + user_id + '/execute-actions-email';

    console.log('Keycloak resend_password_payload endpoint_uri', endpoint_uri)

    return keycloakAxios.put(endpoint_uri, resend_password_payload, {
        headers: headers,
        withCredentials: true
    })
}

export { resendPassword };
