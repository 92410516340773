import dev from "./dev";
import local from "./local";
import axios from "axios";

const config = { dev, local }[process.env.REACT_APP_ENV];

const keycloakAxios = axios.create(); // Cors issue with apiKey


export { config, keycloakAxios };

