import React from "react";
import { FaArrowLeft } from "react-icons/fa6";
import Button from "../../../../components/ui/Button";

const Payment = ({ setStep, ...props }) => {
  return (
    <div className="d-flex flex-column gap-5">
      <span className="d-flex gap-3 align-items-center">
        <FaArrowLeft
          size={36}
          cursor={"pointer"}
          onClick={() => {
            setStep(2);
          }}
        />
        <h2>Étape 4: Paiement</h2>
      </span>

      <div>Paiement</div>
      <span>
        {
          <Button
            styled="primary"
            title="next"
            onClick={() => {
              setStep(4);
            }}
          />
        }
      </span>
    </div>
  );
};

export default Payment;
