import { useEffect, useState } from "react";
import Button from "../../components/ui/Button";
import { FiX } from "react-icons/fi";
import Modal from "react-modal";
import "./personLinks.scss";
import Dropdown from "../../components/ui/Dropdown";
import { getAllPersons } from "../personne/services";
import { getAllCompanies } from "../companies/services";
import { getAllRelations } from "./services";

const ModalStyle = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "500px",
    padding: "0px",
  },
};

const AddPersonLinkModal = ({
  personId,
  personType,
  addRelation,
  setPersonUpdated,
  addRelationModalIsOpen,
  setAddRelationModalIsOpen,
  ...props
}) => {
  const [addRelationData, setAddRelationData] = useState({});
  const [allRelations, setAllRelations] = useState([]);
  const [allPersons, setAllPersons] = useState([]);
  const [allCompanies, setAllCompanies] = useState([]);

  const handleCloseModal = () => {
    setAddRelationModalIsOpen(false);
    setAddRelationData({});
  };
  const getRelations = () => {
    getAllRelations()
      .then((response) => {
        const AllRelations = response.data.map((el) => {
          return { id: el.id, value: el.code, label: el.label };
        });
        setAllRelations([
          { id: null, value: null, label: "Aucune relation selectionnée" },
          ...AllRelations,
        ]);
      })
      .catch((e) => console.log(e));
  };

  const getActors = () => {
    if (personType === "LP") {
      getAllCompanies()
        .then((response) => {
          const AllCompanies = response.data
            .filter((el) => el.id !== personId)
            .map((el) => {
              return {
                id: el.id,
                value: el.id,
                label: el.name,
              };
            });
          setAllCompanies([
            { id: null, value: null, label: "Aucune entreprise selectionnée" },
            ...AllCompanies,
          ]);
        })
        .catch((e) => console.log(e));
    } else {
      getAllPersons()
        .then((response) => {
          const AllPersons = response.data
            .filter((el) => el.id !== personId)
            .map((el) => {
              return {
                id: el.id,
                value: el.id,
                label: el.firstName + " " + el.lastName,
              };
            });
          setAllPersons([
            { id: null, value: null, label: "Aucune personne selectionnée" },
            ...AllPersons,
          ]);
        })
        .catch((e) => console.log(e));
    }
  };

  const handleSubmit = () => {
    const data = {
      ...addRelationData, personType
    };
    addRelation(personId, data)
      .then((response) => {
        setPersonUpdated((prevState) => {
          return !prevState;
        });
        handleCloseModal();
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getRelations();
    getActors();
  }, [personId]);

  return (
    <Modal isOpen={addRelationModalIsOpen} style={ModalStyle}>
      <div className="modal-header">
        <h5>Ajouter une relation</h5>
        <FiX
          size={24}
          style={{ cursor: "pointer" }}
          onClick={() => {
            handleCloseModal();
          }}
        />
      </div>
      <div className="d-flex flex-column p-4 gap-4">
        <Dropdown
          label={"Type de relation"}
          value={addRelationData.relation}
          options={allRelations}
          setValue={(value) => {
            setAddRelationData({ ...addRelationData, relation: value });
          }}
        />
        <span>Avec:</span>
        <div className="d-flex gap-4">
          <span
            hidden={personType === "NP"}
            style={{
              color: personType === "LP" && "#009c6a",
              cursor: "pointer",
            }}
          >
            Entreprise
          </span>
          <span
            hidden={personType === "LP"}
            style={{
              color: personType === "NP" && "#009c6a",
              cursor: "pointer",
            }}
          >
            Personne
          </span>
        </div>
        <Dropdown
          value={addRelationData.toPerson}
          options={personType === "LP" ? allCompanies : allPersons}
          setValue={(value) => {
            setAddRelationData({ ...addRelationData, toPerson: value });
          }}
        />

        <div className="d-flex justify-content-end gap-3">
          <Button
            title={"Annuler"}
            styled={"secondary"}
            onClick={handleCloseModal}
          />
          <Button
            title={"Ajouter une relation"}
            styled={"primary"}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddPersonLinkModal;
